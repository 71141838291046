<template>
  <div class="photos-documents">
    <div class="photos-documents--title">
      <TextLabel
        class="photos-documents-title-label"
        :label="$t('returns.sections.photosAndDocuments.title')"
      />
      <TextLabel
        class="photos-documents-subtitle-label"
        :label="$t('returns.sections.photosAndDocuments.subtitle')"
      />
    </div>

    <div class="photos-documents--content">
      <div class="photos-documents--content-divider">
        <TextLabel
          customClass="section-title"
          :label="$t('returns.sections.photosAndDocuments.external')"
        />
        <div class="divider" />
        <UploadFile
          pov="front"
          :disabled="disabled"
          @onUpload="onUpload('front', $event, 'img')"
          
          :title="$t('returns.sections.photosAndDocuments.inputs.front.title')"
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.front.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="leftSide"
          :disabled="disabled"
          @onUpload="onUpload('leftSide', $event, 'img')"
          :title="
            $t('returns.sections.photosAndDocuments.inputs.leftSide.title')
          "
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.leftSide.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="rightSide"
          :disabled="disabled"
          @onUpload="onUpload('rightSide', $event, 'img')"
          :title="
            $t('returns.sections.photosAndDocuments.inputs.rightSide.title')
          "
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.rightSide.title')
          "
        ></UploadFile>
        <UploadFile
          pov="rear"
          :disabled="disabled"
          @onUpload="onUpload('rear', $event, 'img')"
          :title="$t('returns.sections.photosAndDocuments.inputs.rear.title')"
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.rear.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="rims"
          :disabled="disabled"
          @onUpload="onUpload('rims', $event, 'img')"
          :title="$t('returns.sections.photosAndDocuments.inputs.rims.title')"
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.rims.subtitle')
          "
        ></UploadFile>
      </div>
      <div class="photos-documents--content-divider">
        <TextLabel
          customClass="section-title"
          :label="$t('returns.sections.photosAndDocuments.interior')"
        />
        <div class="divider" />
        <UploadFile
          pov="panel"
          :disabled="disabled"
          @onUpload="onUpload('panel', $event, 'img')"
          :title="$t('returns.sections.photosAndDocuments.inputs.panel.title')"
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.panel.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="frontInterior"
          :disabled="disabled"
          @onUpload="onUpload('frontInterior', $event, 'img')"
          :title="
            $t('returns.sections.photosAndDocuments.inputs.frontInterior.title')
          "
          :subtitle="
            $t(
              'returns.sections.photosAndDocuments.inputs.frontInterior.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="rearSeat"
          :disabled="disabled"
          @onUpload="onUpload('rearSeat', $event, 'img')"
          :title="
            $t('returns.sections.photosAndDocuments.inputs.rearSeat.title')
          "
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.rearSeat.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="suitcase"
          :disabled="disabled"
          @onUpload="onUpload('suitcase', $event, 'img')"
          :title="
            $t('returns.sections.photosAndDocuments.inputs.suitcase.title')
          "
          :subtitle="
            $t('returns.sections.photosAndDocuments.inputs.suitcase.subtitle')
          "
        ></UploadFile>
      </div>
      <div class="photos-documents--content-no">
        <div class="photos-documents--content-divider">
          <TextLabel
            customClass="section-title"
            :label="$t('returns.sections.photosAndDocuments.documents')"
          />
          <div class="divider" />
          <UploadFile
            pov="booklet"
            :disabled="disabled"
            @onUpload="onUpload('booklet', $event, 'doc')"
            :title="
              $t('returns.sections.photosAndDocuments.inputs.booklet.title')
            "
            :subtitle="
              $t('returns.sections.photosAndDocuments.inputs.booklet.subtitle')
            "
          ></UploadFile>
          <UploadFile
            v-if="isVisible"
            pov="backBooklet"
            :disabled="disabled"
            @onUpload="onUpload('backBooklet', $event, 'doc')"
            :title="
              $t('returns.sections.photosAndDocuments.inputs.backBooklet.title')
            "
            :subtitle="
              $t('returns.sections.photosAndDocuments.inputs.backBooklet.subtitle')
            "
          ></UploadFile>
          <UploadFile
            v-if="isVisible"
            pov="inspectionFront"
            :disabled="disabled"
            @onUpload="onUpload('inspectionFront', $event, 'doc')"
            :title="
              $t('returns.sections.photosAndDocuments.inputs.inspectionFront.title')
            "
            :subtitle="
              $t('returns.sections.photosAndDocuments.inputs.inspectionFront.subtitle')
            "
          ></UploadFile>
          <UploadFile
            v-if="isVisible"
            pov="inspectionBack"
            :disabled="disabled"
            @onUpload="onUpload('inspectionBack', $event, 'doc')"
            :title="
              $t('returns.sections.photosAndDocuments.inputs.inspectionBack.title')
            "
            :subtitle="
              $t('returns.sections.photosAndDocuments.inputs.inspectionBack.subtitle')
            "
          ></UploadFile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Company } from "@/enums/company";
import { mapGetters } from "vuex";

import { TextLabel } from "@sc/ds-ui-component-library";

export default {

  name: "PhotosAndDocuments",
  components: {
    UploadFile: () => import("../../organisms/UploadFile/UploadFile.vue"),
    TextLabel,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    onUpload(name, event, category) {
      try {
        this.$store.dispatch("ReturnModule/storeAddPhoto", {
          id: this.id, file: {
            name,
            file: event.content,
            category,
            filename: event.name
          }
        }).then((response) => {
        this.$store.commit("SpinnerModule/setLoading", false, { root: true });
          if (response.status == 200) {
            this.$store.commit("AlertModule/setAlert", {
              message: this.$t("returns.sections.photosAndDocuments.alert.storeRetakeFile.success"),
              type: "success",
              display: true,
              global: true,
            });

            const index = this.files.findIndex((x) => x.name === name);
            if (index > -1) {
              this.files.splice(index, 1);
            }
            this.files.push({
              name,
              file: { name: event.name, content: response.data.data },
              category,
            }
            );
            this.$emit("onUpload", this.files);
          } else {
            this.$store.commit("AlertModule/setAlert", {
              message: this.$t("returns.sections.photosAndDocuments.alert.storeRetakeFile.error"),
              type: "error",
              display: true,
              global: true,
            });
          }
        });
      } catch (e) {
        this.$store.commit("SpinnerModule/setLoading", false, { root: true });
        this.$store.commit("AlertModule/setAlert", {
          message: this.$t("returns.sections.photosAndDocuments.alert.storeRetakeFile.error"),
          type: "error",
          display: true,
          global: true,
        });
      }
    },

  },
  computed: {
    ...mapGetters({ getReturnImages: "ReturnModule/getReturnImages" }),
    disabled() {
      return this.getReturn?.submitted;
    },
    isVisible(){
      return process.env.VUE_APP_COMPANY_ID === Company.CEVES;
    }
  },
};
</script>

<style lang="scss" scoped>
.photos-documents {
  display: flex;
}

.photos-documents--title {
  width: 33%;
  padding-right: 50px;
  text-align: left;

  .photos-documents-title-label {
    --text-label-font-size: 16px;
    --text-label-line-height: 16px;
    --text-label-weight: bold;
    --text-label-color: var(--cv-black);
  }

  .photos-documents-subtitle-label {
    --text-label-font-size: 14px;
    --text-label-line-height: 18px;
    --text-label-color: var(--color-2);
    margin-top: 8px;
  }
}

.photos-documents--content,
.photos-documents--content-divider {
  width: 100%;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.upload-header {
  display: grid;
}

.divider {
  height: 1px;
  background-color: var(--color-5);
  margin-bottom: 16px;
  margin-top: 16px;
}

.section-title {
  text-align: left;
  --text-label-font-size: 16px;
  --text-label-line-height: 16px;
  --text-label-weight: 600;
  --text-label-color: var(--cv-black);
}

.photos-documents--content {
  & > * {
    margin: 40px 0;
  }

  & > *:first-child {
    margin: 0 0 0 0;
  }
}

@media (max-width: 1024px) {
  .photos-documents {
    flex-direction: column;
  }

  .photos-documents--title {
    width: 100%;
    margin-bottom: 24px;
  }

  .photos-documents--content {
    & > * {
      margin: 40px 0;
    }
  }
}
</style>
