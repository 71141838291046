import httpClient from "./httpClient";
import QueryString from "query-string";

const LEAD_URL = "/lead";

const LEAD_TYPES = {
    CONTACT: 41,
};

const buildQueryString = (type) => {
    const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
    utms.type = type;

    let queryString = "?";

    queryString += QueryString.stringify(utms);

    return queryString;
};

function leadContact(payload) {
    const queryString = buildQueryString(LEAD_TYPES.CONTACT);
    return httpClient.post(LEAD_URL + queryString, payload);
}


export { leadContact };
