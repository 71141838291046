<template>
  <Section id="section-1" class="hero">
    <div class="hero--container">
      <div class="eval-input">
        <EvaluationInput
          :labels="evaluationInputLabels"
          :getSuccessfullPlateCall="getSuccessfullPlateCall"
          @submit="submitPlate"
          :mode="plateMode"
        />
      </div>
      <div class="mobile--container">
        <MobileCard :card="sliderCards[0]" @onExpand="onExpand" />
      </div>
      <div class="slider-cards">
        <SlidingCard
          :cards="sliderCards"
          :paginationLabel="$t('homepage.hero.sliderCardPagination')"
          :withCloseBtn="hasCloseButton"
          @onClose="closeSlider"
        />
      </div>
    </div>
  </Section>
</template>

<script>
import { Company } from "@/enums/company";
import {
  Section,
  SlidingCard,
  EvaluationInput,
  MobileCard,
} from "@sc/ds-ui-component-library";

export default {
  // eslint-disable-next-line
  name: "Hero",
  components: {
    Section,
    SlidingCard,
    EvaluationInput,
    MobileCard,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      getSuccessfullPlateCall: false,
    };
  },
  methods: {
    submitPlate(plate) {
      this.$emit("onSubmitPlate", plate);
    },
    onResizeWidth() {
      this.windowWidth = window.innerWidth;
    },
    onExpand() {
      let swiperCard = document.querySelector(".slider-cards");
      swiperCard.style.display = "flex";
    },
    closeSlider() {
      let swiperCard = document.querySelector(".slider-cards");
      swiperCard.style.display = "none";
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResizeWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeWidth);
  },
  computed: {
    hasCloseButton() {
      const isMobileScreen = this.windowWidth < 896;
      let swiperCard = document.querySelector(".slider-cards");

      if (!isMobileScreen && swiperCard) {
        swiperCard.style.display = "flex";
      } else if (isMobileScreen && swiperCard) {
        swiperCard.style.display = "none";
      }

      return isMobileScreen ? true : false;
    },
    evaluationInputLabels() {
      return {
        title: this.$t("homepage.hero.title"),
        description: this.$t("homepage.hero.description"),
        placeholder: this.$t("homepage.hero.placeholder"),
        button: this.$t("homepage.hero.button"),
      };
    },
    sliderCards() {
      return [
        {
          image:
            this.$t("resources.images.homepage.hero1") ||
            require(`@/assets/heroSliderCards/sliderImage.png`),
          title: this.$t("homepage.hero.sliderCard.0.title"),
          description: this.$t("homepage.hero.sliderCard.0.description"),
        },
        {
          image:
            this.$t("resources.images.homepage.hero2") ||
            require(`@/assets/heroSliderCards/sliderImage.png`),
          title: this.$t("homepage.hero.sliderCard.1.title"),
          description: this.$t("homepage.hero.sliderCard.1.description"),
        },
      ];
    },
    plateMode(){
      return process.env.VUE_APP_COMPANY_ID === Company.CEVPT ? 'plate' : 'plateES';
    }
  },
};
</script>

<style lang="scss" scoped>
.hero {
  --section-bg-color: #00064b;
  position: relative;
  font-family: "Mont";
  display: flex;
  flex-direction: row;
  --section-padding: 112px 20px 58px 20px;
  justify-content: center;
  align-items: center;
}

:deep(.eval-input-container) > * + * {
  margin-top: 35px;
}

:deep(.eval-input-container) {
  gap: 0px !important;
}

:deep(.eval-input-container .information) {
  gap: 0px !important;
}
:deep(.eval-input-container .information) > * + * {
  margin-top: 19px;
}

.hero--container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  justify-content: space-between;

  .eval-input {
    margin-top: 50px;

    ::v-deep {
      .title {
        text-align: left;
      }
      .submit-information {
        .button {
          font-family: "Mont";
          --eval-input-button-width: 290px;
          white-space: nowrap;
        }
        .wrapper {
          min-width: 310px;
          .indicator--container {
            max-width: 310px;
            --color-info: var(--theme-color-quaternary);
            .indicator--info svg {
              filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
                brightness(119%) contrast(119%);
            }
            .text-label {
              text-align: start;
            }
          }
          .input--input {
            --baseinput-padding-top: 0px;
            --baseinput-padding-bottom: 0px;
          }
        }
        .input--input {
          &::placeholder {
            font-family: "Mont";
          }
        }
        .input--text {
          font-family: "Mont";
        }
      }
    }
  }
  .mobile--container {
    display: none;
  }
}

:deep(.slider-cards .information) > * + * {
  margin-top: 16px;
}

:deep(.slider-cards .pagination) > * + * {
  margin-left: 6px;
}

@media (min-width: 1440px) {
  .hero {
    --section-padding: 100px 70px 50px 70px;
  }
  .slider-cards {
    --sc-c-information-gap: 0px;
    --sc-c-information-padding: 16px;
    --sc-c-information-title-font-size: 18px;
    --sc-c-information-title-line-height: 23px;
    --sc-c-information-description-font-size: 14px;
    --sc-c-information-description-line-height: 18px;
    --sc-c-information-pagination-font-size: 13px;
    --sc-c-information-pagination-line-height: 13px;
    --sc-c-information-pagination-gap: 0px;
    --sc-c-information-pagination-margin-top: 25px;
    --sc-c-image-img-max-height: 200px;
    --sc-c-max-width: 360px;
    --sc-c-min-width: 360px;
    --sc-c-image-max-height: 307px;
  }
}

@media (max-width: 1530px) {
  .hero {
    --section-padding: 100px 70px 50px 70px;
  }
  .eval-input {
    ::v-deep .submit-information {
      max-width: 50vw;
    }
  }
  .hero--container {
    .slider-cards {
      margin: auto 0 auto auto;
      --sc-c-max-width: 25vw;
    }
  }

  .hero--container > * + * {
    margin-top: 79px;
  }
}

@media (max-width: 1367px) {
  .hero {
    --section-padding: 90px 70px 28px 70px;
  }
}

@media (max-width: 1280px) {
  .hero {
    --section-padding: 90px 70px 28px 70px;
  }
}

@media (max-width: 1115px) {
  .hero--container {
    flex-direction: column;
  }
}

@media (max-width: 896px) {
  .hero {
    --section-padding: 10px 24px 28px 24px;
    height: 585px;
  }
  ::v-deep .description {
    text-align: left;
    .icon {
      display: none;
    }
  }

  .slider-cards {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
  .mobile--container {
    display: flex !important;
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
  .slider-cards {
    display: none;
  }
}

@media (max-width: 475px) {
  .hero {
    height: 545px;
  }
  .eval-input :deep(.submit-information) {
    max-width: unset;
  }

  .eval-input {
    :deep(.title) .text-label {
      --text-label-font-size: 32px;
      --text-label-line-height: 42px;
    }

    :deep(.description) .text-label {
      --text-label-line-height: 21px;
      --text-label-weight: 600;
    }

    .eval-input-container :deep(.information) .submit-information .input--text {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .eval-input :deep(.submit-information) .wrapper {
    min-width: unset !important;
  }
  .slider-cards{
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }
  .mobile--container {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .slider-cards {
    --swiper-container-width: 85vw;
    --sc-c-max-width: 50px;
    --sc-c-close-top: 16px;
    --sc-c-close-right: 16px;
  }

  .mobile--container {
    --mc-max-width: 172px;
  }
}
</style>
