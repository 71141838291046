<template>
  <div class="personal-info--container">
    <div class="information">
      <Avatar
        class="avatar"
        :username="username"
        :size="80"
        backgroundColor="white"
        color="#D3D9DE"
      />
      <TextLabel customClass="title" v-html="titleLabel" />
    </div>
    <TextLabel customClass="description" :label="descriptionLabel" />
  </div>
</template>

<script>
import { TextLabel } from "@sc/ds-ui-component-library";
import Avatar from "vue-avatar";

export default {
  name: "PersonalInfo",
  components: {
    TextLabel,
    Avatar,
  },
  props: {
    username: {
      type: String,
      default: "",
    },
    titleLabel: {
      type: String,
    },
    descriptionLabel: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-info--container > * + * {
  margin-top: 24px;
}

.personal-info--container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  font-family: "Mont";

  .information > * + * {
    margin-top: 16px;
  }

  .information {
    display: flex;
    flex-direction: column;

    ::v-deep .vue-avatar--wrapper span {
      font-family: "Mont";
      color: var(--theme-color-quinary);
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
    }

    .title {
      --text-label-color: var(--color-7);
      --text-label-font-size: 16px;
      --text-label-line-height: 21px;
      --text-label-weight: 600;
    }
  }
  .description {
    --text-label-color: var(--color-7);
    --text-label-font-size: 14px;
    --text-label-line-height: 18px;
    --text-label-weight: 500;
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .personal-info--container {
    .information {
      :deep(.vue-avatar--wrapper) span {
        font-weight: 800;
        font-size: 22px;
        line-height: 22px;
      }
      :deep(.vue-avatar--wrapper) {
        width: 56px !important;
        height: 56px !important;
      }
    }
  }
}
</style>
