<template>
  <div class="estimated-value">
    <img :src="carLogo" alt="logo carro" class="estimate-retake-car" />
    <div class="estimated-value-info">
      <Subtitle :label="$t('returns.sections.estimatedValue.title')" />
      <TextLabel class="title" :label="priceLabel" />
    </div>
  </div>
</template>

<script>
import { getPriceFormattedNoDecimal } from "../../../utils/currency.js";

import { TextLabel, Subtitle } from "@sc/ds-ui-component-library";

export default {
  name: "EstimatedValue",
  components: {
    TextLabel,
    Subtitle,
  },
  props: {
    price: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    priceLabel() {
      if (this.price === 0) return this.$t("emptyRetakeValuation");
      return getPriceFormattedNoDecimal(this.price);
    },
    carLogo() {
      return require("../../../assets/retakePanel/carro.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.estimated-value {
  display: flex;
  align-items: center;

  background-color: #fbfbfb;
  padding: 24px;

  --subtitle-font-size: 16px;
  --subtitle-line-height: 16px;
  --subtitle-weight: 600;
  --subtitle-color: var(--cv-black);

  --text-label-font-size: 18px;
  --text-label-line-height: 18px;
  --text-label-color: var(--cv-secondary);
  --text-label-weight: Bold;
}

.estimated-value-info {
  display: flex;

  .title {
    margin-left: 8px;
  }
}

@media (max-width: 768px) {
  .estimated-value-info {
    flex-direction: column;
    text-align: left;
    gap: 8px;

    .title {
      margin-left: 0px;
      --text-label-weight: 800;
    }
    .subtitle {
      --subtitle-line-height: 21px;
      --subtitle-weight: 700;
    }
  }
}
</style>
