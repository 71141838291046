import httpClient from "./httpClient";

const FOOTPRINT = "/footprint";

const FOOTPRINT_TYPES = {
  PAGE_VIEW: 1,
  COOKIE_PAGE: 15,
  FAQS: 22,
  RETAKE_STEP_1: 41,
  RETAKE_STEP_2: 42,
  RETAKE_STEP_3: 43,
  RETAKE_EVAL: 44,
};

function registerEvent(footprintType, payload) {
  const footprintEvent = {
    type: footprintType,
    data: payload,
  };

  return httpClient.post(FOOTPRINT, footprintEvent);
}

export { FOOTPRINT_TYPES, registerEvent };