<template>
  <Section :id="$t('homepage.faqsAccordion.url')" class="faqs--section">
    <Heading
      class="faqs--title"
      tag="h2"
      :label="$t('homepage.faqsAccordion.title')"
    />
    <div class="faqs--container">
      <FaqsAccordion
        :key="faqAccordionKey"
        :faqs="faqs"
        @onToggleFaq="onToggleFaq"
      />
    </div>
    <div class="btn--container">
      <Button type="tertiary" @onClick="expandAll" :label="btnLabel" />
    </div>
  </Section>
</template>

<script>
import {
  Section,
  FaqsAccordion,
  Heading,
  Button,
} from "@sc/ds-ui-component-library";
export default {
  components: {
    Heading,
    FaqsAccordion,
    Section,
    Button,
  },
  data() {
    return {
      faqAccordionKey: 123,
      faqsContent: [],
      getFaqsContent: [],
      isAllExpanded: false,
      // Value of default displayed faqs
      faqsLengthLimit: 4,
    };
  },
  watch: {
    faqsContent(before) {
      this.getFaqsContent = before;
    },
  },
  computed: {
    getIsAllExpanded() {
      return this.isAllExpanded;
    },
    btnLabel() {
      return this.isAllExpanded
        ? this.$t("homepage.faqsAccordion.collapseBtnLabel")
        : this.$t("homepage.faqsAccordion.expandBtnLabel");
    },
    faqs() {
      return this.initializeFaqsAccordion(
        this.$t("homepage.faqsAccordion.cardsContent")
      );
    },
  },
  methods: {
    onToggleFaq(faq) {
      this.$emit("onToggleFaq", faq);
      this.getFaqsContent = this.getFaqsContent.map((faqDisplay) => {
        if (faqDisplay.id === faq.id) {
          faqDisplay.open = faq.open;
        }
        return faqDisplay;
      });
    },
    expandAll() {
      this.isAllExpanded
        ? this.getFaqsContent.map((faq, index) => {
            faq.display = index > 4 ? false : true;
            faq.open = index > 4 ? false : faq.open;
            return faq;
          })
        : this.getFaqsContent.map((faq) => {
            faq.display = true;
            return faq;
          });

      const checkIsOpen = (obj) => obj.display;
      this.isAllExpanded = this.getFaqsContent.every(checkIsOpen);
      this.faqAccordionKey++;
    },
    initializeFaqsAccordion(faqsAccordion) {
      this.faqsContent = faqsAccordion?.map((faq, index) => {
        faq.id = index;
        faq.open = false;
        faq.display = index > this.faqsLengthLimit ? false : true;

        return faq;
      });
      return this.faqsContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.faqs--section {
  --section-bg-color: #ffffff;
  --section-padding: 96px 0px 46px 0px;
}
.faqs--container {
  margin-top: 56px;
}
.faqs--title {
  --heading-large-font-size: 28px;
  --heading-large-line-height: 28px;
  --heading-color: var(--cv-secondary);
}
.btn--container {
  display: flex;
  justify-content: center;
  margin-top: 56px;
  ::v-deep {
    .tertiary {
      font-family: Mont;
      --button-tertiary-border: 1px solid #00064b;

      &:hover,
      &:active {
        --button-tertiary-hover: #2cfe71;
        --button-text-color: #00064b;
        --button-tertiary-border: none;
      }
    }
  }
}

@media (max-width: 1440px) {
  .faqs_section {
    --section-padding: 96px 90px 46px 90px;
  }
}

@media (max-width: 896px) {
  .faqs_section {
    --section-padding: 30px 30px 16px 30px;
    ::v-deep .accordion--item {
      --mobile-fa-c-item-padding: 23px 15px;
    }
  }
  ::v-deep .heading {
    --heading-large-font-size: 24px;
    --heading-large-line-height: 24px;
    --heading-weight: 800;
  }
}

@media (max-width: 475px) {
  .faqs_section {
    --section-padding: 30px 30px 16px 30px;
    
    :deep(.accordion--item) {
      --heading-medium-line-height: 23px;
    }
    :deep(.accordion--item-title) {
      --heading-medium-line-height: 23px;
    }
  }
}
</style>
