<template>
  <div class="lead-retake-form">
    <InputField
      :key="'plate' + plate"
      :label="labels.inputs.plate"
      :mode="plateMode"
      :value="plate"
      :disabled="plate.length > 0"
      @onInput="(e) => handleEmit('plate', e)"
      ref="plate"
      customClass="cv-input-container"
      :icon="'locker'"
    />
    <div class="single-row">
      <div class="month">
        <Dropdown
          selectedOnHeader
          :items="months"
          :key="'monthKey'"
          :name="labels.inputs.month"
          @onSelectedItems="handleMonth"
        />
        <Indicator
          key="months-indicator-01"
          v-if="!validationFlags.month"
          :type="`error`"
          :label="labels.indicators.month"
          :icon="`exclamation-mark`"
        />
      </div>
      <div class="year">
        <Dropdown
          selectedOnHeader
          :items="years"
          :key="'yearKey'"
          :name="labels.inputs.year"
          @onSelectedItems="handleYear"
        />
        <Indicator
          key="year-indicator-01"
          v-if="!validationFlags.year"
          :type="`error`"
          :label="labels.indicators.year"
          :icon="`exclamation-mark`"
        />
      </div>
    </div>
    <Dropdown
      selectedOnHeader
      v-if="plate"
      :key="'brandsKey'"
      :name="labels.inputs.brand"
      :items="brands"
      @onSelectedItems="(e) => handleEmit('brand', e)"
    />
    <Indicator
      key="brand-indicator-01"
      v-if="!validationFlags.brand"
      :type="`error`"
      :label="labels.indicators.brand"
      :icon="`exclamation-mark`"
    />
    <Indicator
      key="noModels-indicator-01"
      v-if="hasSelected(brands) && models.length < 1"
      :type="`info`"
      :label="labels.indicators.noModels"
      :icon="`exclamation-mark`"
    />
    <Dropdown
      v-if="hasSelected(brands) && models.length > 0"
      selectedOnHeader
      :key="'modelsKey'"
      :name="labels.inputs.model"
      :items="models"
      @onSelectedItems="(e) => handleEmit('model', e)"
    />
    <Indicator
      key="model-indicator-01"
      v-if="!validationFlags.model"
      :type="`error`"
      :label="labels.indicators.model"
      :icon="`exclamation-mark`"
    />
    <Dropdown
      v-if="hasSelected(models) && versions.length > 0"
      selectedOnHeader
      :key="'versionsKey'"
      :items="versions"
      :name="labels.inputs.version"
      @onSelectedItems="(e) => handleEmit('version', e)"
    />
    <Indicator
      key="version-indicator-01"
      v-if="!validationFlags.version && hasSelected(models)"
      :type="`error`"
      :label="labels.indicators.version"
      :icon="`exclamation-mark`"
    />
    <Dropdown
      v-if="hasSelected(models) && hasSelected(versions) && hasValidFuelOptions"
      selectedOnHeader
      :key="'fuelsKey'"
      :name="labels.inputs.fuel"
      :items="fuels"
      @onSelectedItems="(e) => handleEmit('fuel', e)"
    />
    <Indicator
      key="fuel-indicator-01"
      v-if="!validationFlags.fuel"
      :type="`error`"
      :label="labels.indicators.fuel"
      :icon="`exclamation-mark`"
    />
    <InputField
      v-if="hasSelected(versions) && hasSelected(fuels)"
      :value="`${kilometers}`"
      :label="labels.inputs.kms"
      :country="country"
      mode="kms"
      @onInput="(e) => handleEmit('kilometers', e)"
      :key="kmRenderKey"
      ref="kilometers"
      customClass="cv-input-container"
    />
  </div>
</template>

<script>
import { Company } from "@/enums/company";
import { Dropdown, InputField, Indicator } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

const range = (n, from = 1) =>
  [...Array(n).keys()]
    .map((i) => i + from)
    .map((it) => ({ key: it, value: `${it}` }));

export default {
  name: "LeadRetake",
  components: {
    Dropdown,
    InputField,
    Indicator,
  },
  data() {
    return {
      validationFlags: {
        month: true,
        year: true,
        brand: true,
        model: true,
        version: true,
        fuel: true,
        kilometers: true,
      },
      firstLoad: true,
      kmRenderKey: 0,
      validating: false,
    };
  },
  props: {
    labels: { type: Object },
  },
  methods: {
    getFirst(arr) {
      const selected = arr.filter((it) => it.selected);
      if (selected.length) return selected[0];
      else return false;
    },
    handleMonth(e) {
      this.validationFlags.month = this.getFirst(e).selected;
      this.$store.commit(
        "RetakeModule/setMonth",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
    },
    handleYear(e) {
      this.validationFlags.year = this.getFirst(e).selected;
      this.$store.commit(
        "RetakeModule/setYear",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
    },
    handleDate(e) {
      this.$store.commit("RetakeModule/setPlateDate", e);
    },
    handleLicense([e, valid]) {
      if (valid && !this.validating) {
        this.$store.commit("RetakeModule/setPlate", e);
        this.$store.dispatch("RetakeModule/storeVehicleByPlate");
        this.kmRenderKey += 1;
      }
    },
    handleKilometers(e) {
      this.validationFlags.kilometers = e[0];
      this.$store.commit("RetakeModule/setKilometers", e[0]);
    },
    handleFuel(e) {
      if (this.hasValidFuelOptions) {
        this.validationFlags.fuel = e[0];
        this.$store.commit("RetakeModule/setFuel", e[0].value);
      } else {
        this.$store.commit("RetakeModule/setFuel", null);
      }
    },
    handleBrand(e) {
      this.validationFlags.brand = this.getFirst(e).selected;
      this.$store.commit(
        "RetakeModule/setBrand",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
      if (this.getFirst(e)) {
        this.$store.dispatch("RetakeModule/storeModels");
      }
      if (!this.firstLoad) {
        this.$store.commit("RetakeModule/setModels", {});
        this.$store.commit("RetakeModule/setVersions", {});
        this.$store.commit("RetakeModule/setVersion", "");
      }
    },
    handleModel(e) {
      this.validationFlags.model = this.getFirst(e).selected;
      this.$store.commit(
        "RetakeModule/setModel",
        this.getFirst(e) ? this.getFirst(e) : ""
      );
      if (this.getFirst(e)) this.$store.dispatch("RetakeModule/storeVersions");
      if (!this.firstLoad) {
        this.$store.commit("RetakeModule/setVersions", {});
        this.$store.commit("RetakeModule/setVersion", "");
      }
    },
    handleVersion(e) {
      this.validationFlags.version = this.getFirst(e).selected;
      this.$store.commit(
        "RetakeModule/setVersion",
        this.getFirst(e) ? this.getFirst(e).value : ""
      );
      this.$store.commit(
        "RetakeModule/setBcaId",
        this.getFirst(e) ? this.getFirst(e).key : ""
      );
      var selectedVersion = this.versions.find((el) => el.selected);
      if(selectedVersion){
        this.$store.commit(
          "RetakeModule/setFuel",
          {key: selectedVersion.fuelId, value: selectedVersion.fuel}
        );
        this.$store.commit(
          "RetakeModule/setTransmission",
          {key: selectedVersion.transmissionId, value: selectedVersion.transmission}
        );
      }

    },
    handlePlate([plate, valid]) {
      if (valid && !this.validating) {
        this.$store.dispatch("RetakeModule/cleanRetake");
        this.$store.commit("RetakeModule/setPlate", plate);
        this.$store.dispatch("RetakeModule/storeVehicleByPlate");
      }
    },
    handleEmit(input, e) {
      switch (input) {
        case "kilometers":
          this.handleKilometers(e);
          break;
        case "brand":
          this.handleBrand(e);
          break;
        case "model":
          this.handleModel(e);
          break;
        case "version":
          this.handleVersion(e);
          break;
        case "plate":
          this.handlePlate(e);
          break;
        case "fuel":
          this.handleFuel(e);
          break;
        default:
      }
      this.$emit(
        "onValid",
        this.brand &&
          this.model &&
          this.plate &&
          this.year &&
          this.month &&
          (!this.hasValidFuelOptions ? true : this.fuel) &&
          this.kilometers > 99 &&
          this.kilometers < 1000000000
      );
    },
    formatDigit(n) {
      return n.toLocaleString(navigator.language, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    hasSelected(arr) {
      return arr.filter((val) => val.selected).length > 0;
    },
    showLabels(ref) {
      this.$refs[ref].forceValidation();
    },
    validateInputs() {
      this.validating = true;
      this.showLabels("plate");
      this.validationFlags.month = !!this.month;
      this.validationFlags.year = !!this.year;
      this.validationFlags.brand = !!this.brand;
      if (this.hasSelected(this.brands) && this.models.length > 0)
        this.validationFlags.model = !!this.model;
      if (this.hasSelected(this.models) && this.versions.length > 0)
        this.validationFlags.version = !!this.version;
      if (this.hasSelected(this.models) && this.hasSelected(this.versions))
        this.showLabels("kilometers");
      this.validating = false;
    },
  },
  computed: {
    ...mapGetters({
      plateDate: "RetakeModule/getPlateDate",
      plate: "RetakeModule/getPlate",
      brands: "RetakeModule/getBrands",
      models: "RetakeModule/getModels",
      versions: "RetakeModule/getVersions",
      brand: "RetakeModule/getBrand",
      model: "RetakeModule/getModel",
      version: "RetakeModule/getVersion",
      kilometers: "RetakeModule/getKilometers",
      fuel: "RetakeModule/getFuel",
      fuels: "RetakeModule/getFuels",
      year: "RetakeModule/getYear",
      month: "RetakeModule/getMonth",
    }),
    country() {
      return process.env.VUE_APP_COUNTRY;
    },
    months() {
      return range(12).map((it) =>
        parseInt(it.key) === parseInt(this.month)
          ? {
              key: this.formatDigit(it.key),
              value: this.formatDigit(it.key),
              selected: true,
            }
          : {
              key: this.formatDigit(it.key),
              value: this.formatDigit(it.key),
            }
      );
    },
    years() {
      return range(100, 1922)
        .reverse()
        .map((it) =>
          parseInt(it.key) === parseInt(this.year)
            ? { ...it, selected: true }
            : it
        );
    },
    hasValidFuelOptions() {
      return this.fuels.some((f) => f.value);
    },
    plateMode(){
      return process.env.VUE_APP_COMPANY_ID === Company.CEVPT ? 'plate' : 'plateES';
    }
  },
  mounted() {
    this.handleEmit();
    this.firstLoad = false;
  },
};
</script>
<style lang="scss" scoped>
.lead-retake-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: start;
  height: 100%;

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin: 0 0 8px 0;
  }

  & > *:last-child {
    margin: 8px 0 0 0;
  }

  :deep(.dropdown-header) {
    &.box {
      .dropdown-header-inner {
        --text-label-color: var(--cv-black);
        --text-label-weight: 600;
      }
    }

    &.selected {
      .dropdown-header-inner {
        --text-label-color: var(--cv-secondary);
        --text-label-weight: 600;
      }
    }
  }
}

.single-row {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > *:first-child {
    margin: 0 16px 0 0;
  }

  ::v-deep .dropdown--wrapper {
    width: 100%;
  }
}

.month,
.year {
  width: 100%;
}

.swiper-container {
  --sc-c-border-radius: 6px;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.swiper-container :deep(.title) {
  --text-label-font-size: 18px;
  --text-label-line-height: 23px;
}

.swiper-container :deep(.description) {
  --text-label-font-size: 14px;
  --text-label-line-height: 18px;
}

.lead-retake-form {
  --input-disabled-opacity: 1;
}

@media (max-width: 896px) {
  .single-row {
    flex-direction: column;

    & > * {
      margin: 8px 0;
    }

    & > *:first-child {
      margin: 0 0 8px 0;
    }

    & > *:last-child {
      margin: 8px 0 0 0;
    }
  }

  .lead-retake-form {
    & > * {
      min-height: 56px;
    }

    .single-row {
      min-height: 128px;
    }
  }
}
</style>
