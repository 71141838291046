import { render, staticRenderFns } from "./FaqsSection.vue?vue&type=template&id=7ca4c242&scoped=true&"
import script from "./FaqsSection.vue?vue&type=script&lang=js&"
export * from "./FaqsSection.vue?vue&type=script&lang=js&"
import style0 from "./FaqsSection.vue?vue&type=style&index=0&id=7ca4c242&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca4c242",
  null
  
)

export default component.exports