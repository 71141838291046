<template>
  <div :class="['icon--container', iconSizeClass]">
    <font-awesome-icon
      :icon="[iconPrefix, name]"
      :class="['icon', customClass, typeColor]"
      :style="customStyles"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line 
  name: "Icon",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "small",
      validator: (size) => {
        return ["x-small", "small", "medium", "large", "x-large"].includes(
          size
        );
      },
    },
    color: {
      type: String,
      default: "primary",
    },
    iconPrefix: {
      type: String,
      default: "fas",
    },
  },
  computed: {
    iconSizeClass() {
      return {
        // size
        "icon--container-x-small": this.size === "x-small",
        "icon--container-small": this.size === "small",
        "icon--container-medium": this.size === "medium",
        "icon--container-large": this.size === "large",
        "icon--container-x-large": this.size === "x-large",
      };
    },
    customStyles() {
      return {
        ...this.styles,
        color: this.color,
      };
    },
    typeColor() {
      return {
        "icon-container-primary": this.color === "primary",
        "icon-container-secondary": this.color === "secondary",
        "icon-container-tertiary": this.color === "tertiary",
      };
    },
  },
};
</script>

<style lang="css" scoped src="./Icon.css"></style>
