import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
import { PAGES } from "../enums/pages";
import { getUtmParams } from "../utils/helpers";

import { FOOTPRINT_TYPES, registerEvent } from "../api/footprint";

Vue.use(VueRouter);

const lang_rountes  = 
  {
    privacy: {"PT": "/politica-de-privacidade", "ES": "/politica-de-privacidad"},
    terms: {"PT": "/termos-e-condicoes", "ES": "/terminos-y-condiciones"}
  }

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(
      /* webpackChunkName: "home" */"../components/pages/HomePage/HomePage.vue"
    ),
  },
  {
    path:  lang_rountes.privacy[process.env.VUE_APP_COUNTRY],
    name: "privacy",
    component: () =>
      import("../components/pages/PrivacyPolicyPage/PrivacyPolicyPage.vue"),
  },
  {
    path:  lang_rountes.terms[process.env.VUE_APP_COUNTRY],
    name: "terms-conditions",
    component: () =>
      import("../components/pages/TermsConditionsPage/TermsConditionsPage.vue"),
  },
  {
    path: "/aviso-legal",
    name: "legal-advice",
    component: () =>
      import("../components/pages/LegalAdvicePage/LegalAdvicePage.vue"),
  },
  {
    path: "/not-found",
    name: "not-found",
    component: () => import("../components/pages/ErrorPage/ErrorPage"),
  },
  {
    path: "*",
    name: "error",
    component: () => import("../components/pages/ErrorPage/ErrorPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { name: fromName, path: fromPath } = from;
  const { name: toName, path: toPath, query: toQuery } = to;

  const routeName = routes.find((route) => route.path === fromPath)?.name;

  const footprintEvent = {
    from: fromName || routeName || "",
    to: toName,
  };

  const canonicalLinks = document.head.querySelectorAll("link");
  if (canonicalLinks.length)
    canonicalLinks.forEach((link) => {
      if (link.rel === "canonical") link.remove();
    });

  let canonicalUrl = `${window.location.protocol}//${window.location.host}${toPath}`;
  if (canonicalUrl.endsWith("/")) canonicalUrl = canonicalUrl.slice(0, -1);
  const link = document.createElement("link");
  link.rel = "canonical";
  link.href = `${canonicalUrl}`;
  document.head.appendChild(link);

  if (toQuery.action && toQuery.action === "login") {
    await store.dispatch("storeLogin", store.getters.getUserObj);
  }

  if (toQuery.action && toQuery.action === "logout") {
    await store.dispatch("storeLogout");
    store.dispatch("UserModule/cleanUser");
    store.commit("setUserInfo", {});
  }

  const utms = getUtmParams();
  if (Object.keys(utms).length) {
    footprintEvent.utms = utms;
  }

  sessionStorage.setItem("utms", JSON.stringify(utms));

  registerEvent(FOOTPRINT_TYPES.PAGE_VIEW, footprintEvent);

  const loggedInToken = localStorage.getItem("token");

  if (!loggedInToken) {
    await store.dispatch("fetchGuestToken");
  }

  // only applies rule if used is logged in
  if (localStorage.getItem("isGuest") === "false"){
    if(toQuery.action && toQuery.action === "retoma" && toQuery.tradeId && toQuery.tradeId > 0) {
      
      store.commit("ReturnModule/setReturnId", Number(toQuery.tradeId));
      await store.dispatch("DeliveryVehicleModule/fetchDealersDropdown");
      store.commit("PanelModule/setToggleReturn", true);
    }
  }

  document.body.classList.remove("no-scroll");

  if (
    toPath.split("/").includes("personal") &&
    localStorage.getItem("isGuest") === "true"
  ) {
    return next({ name: PAGES.HOME });
  } else {
    return next();
  }
});

export default router;
