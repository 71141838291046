const state = {
    alertStatus: {
      display: false,
      message: "",
      type: "",
      global: false,
    },
  };
  
  const getters = {
    getAlert: (state) => state.alertStatus,
  };
  
  const mutations = {
    setAlert(state, status) {
      state.alertStatus = status;
      setTimeout(() => {
        state.alertStatus = {
          display: false,
          message: "",
          type: "",
          global: false,
        };
      }, 4000);
    },
    clearAlert(state) {
      state.alertStatus = {
        display: false,
        message: "",
        type: "",
        global: false,
      };
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
  };