<template>
  <Section id="section-mini">
    <div class="mini">
      <WidgetMini
        :businessunitId="businessunitId"
        :href="trustpilotHref"
        :dataLocale="locale"
      />
    </div>
  </Section>
</template>

<script>
import { Section, WidgetMini } from "@sc/ds-ui-component-library";

export default {
  name: "TrustPilotMini",
  components: {
    Section,
    WidgetMini,
  },
  props: {
    trustpilotHref: {
      type: String,
    },
    businessunitId: {
      type: String,
    },
    locale: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
#section-mini {
  --section-bg-color: var(--color-7);

  .mini {
    &:after {
      content: "";
      height: 1px;
      width: 30%;
      background-color: var(--color-4);
      position: absolute;
      margin-top: -12px;
      left: 100px;
    }
    &:before {
      content: "";
      height: 1px;
      width: 30%;
      background-color: var(--color-4);
      position: absolute;
      margin-top: 11px;
      right: 100px;
    }
  }
}

@media (min-width: 2000px) {
  #section-mini {
    .mini {
      &:after {
        width: 35%;
        left: 7%;
      }
      &:before {
        width: 35%;
        right: 7%;
      }
    }
  }
}

@media (min-width: 2300px) {
  #section-mini {
    .mini {
      &:after {
        width: 30%;
        left: 13%;
      }
      &:before {
        width: 30%;
        right: 13%;
      }
    }
  }
}

@media (min-width: 2800px) {
  #section-mini {
    .mini {
      &:after {
        width: 25%;
        left: 20%;
      }
      &:before {
        width: 25%;
        right: 20%;
      }
    }
  }
}

@media (min-width: 3300px) {
  #section-mini {
    .mini {
      &:after {
        width: 20%;
        left: 25%;
      }
      &:before {
        width: 20%;
        right: 25%;
      }
    }
  }
}

@media (max-width: 1280px) {
  #section-mini {
    .mini {
      &:after {
        width: 30%;
      }
      &:before {
        width: 30%;
      }
    }
  }
}

@media (max-width: 1000px) {
  #section-mini {
    .mini {
      &:after {
        left: 20px;
      }
      &:before {
        right: 20px;
      }
    }
  }
}

@media (max-width: 800px) {
  #section-mini {
    .mini {
      &:after {
        width: 20vw;
      }
      &:before {
        width: 20vw;
      }
    }
  }
}

@media (max-width: 400px) {
  #section-mini {
    .mini {
      &:after {
        width: 10%;
      }
      &:before {
        width: 10%;
      }
    }
  }
}
</style>
