<template>
  <div class="footer--container">
    <div class="wrapper">
      <div class="logo-container">
        <img :src="appLogo" alt="logo carplus" />
      </div>
      <div class="center--links">
        <div class="links--container">
          <span
            :key="`link-${idx}`"
            v-for="(it, idx) in links.bottom"
            class="bottom--link"
          >
            <Link
              href="javascript:void(0)"
              @onClick="onClickHandler(it)"
              :label="it.name"
              :class="linkClass(idx)"
            />
            <span
              v-if="checkLastItem(links.bottom.length, idx)"
              class="separator"
              >|</span
            >
          </span>
        </div>
        <span class="copyright">{{ $t("footer.copyright",{year}) }}</span>
      </div>
      <div class="right--link">
        <Button
          :label="$t('footer.btnLabel')"
          customClass="contact-button"
          type="quinary"
          @onClick="openContactPopup()"
        />
      </div>
    </div>
    <div class="mobile-center--links">
      <div class="links--container">
        <span
          :key="`link-${idx}`"
          v-for="(it, idx) in links.bottom"
          class="bottom--link"
        >
          <Link
            href="javascript:void(0)"
            @onClick="onClickHandler(it)"
            :label="it.name"
            :class="linkClass(idx)"
          />
          <span v-if="checkLastItem(links.bottom.length, idx)" class="separator"
            >|</span
          >
        </span>
      </div>
      <p class="copyright">{{ $t("footer.copyright",{year}) }}</p>
    </div>
  </div>
</template>

<script>
import { Link, Button } from "@sc/ds-ui-component-library";
export default {
  // eslint-disable-next-line
  data(){
    return {
      year: new Date().getFullYear()

    }
  },
  name: "Footer",
  components: {
    Link,
    Button,
  },
  computed: {
    appLogo() {
      return (
        this.$t("resources.images.appLogo") || require("@/assets/logo.svg")
      );
    },
    links() {
      return {
        bottom: [
          {
            name: this.$t("footer.legalAlert.title"),
            path: this.$t("footer.legalAlert.path"),
          },
          {
            name: this.$t("footer.terms.title"),
            path: this.$t("footer.terms.path"),
          },
          {
            name: this.$t("footer.privacy.title"),
            path: this.$t("footer.privacy.path"),
            target: "_blank",
          },
          {
            name: this.$t("footer.cookies.title"),
            path: '#'
          },
          {
            name: this.$t("footer.complains.title"),
            path: this.$t("footer.complains.path"),
            target: "_blank",
          },
        ],
      };
    },
  },
  methods: {
    linkClass(idx){
      return idx == 3 ? 'js-rgc-open-cookies-settings' : ''
    },
    onClickHandler(it) {
      if (!it.target && it.path !== "#") {
        this.$router.push(it.path).catch((e) => {
          e;
          this.$router.push(it.path);
        });
      }
      else if (it.path === "#") {
        window.scroll(0,0)
      } else {
        window.open(it.path, "_blank");
      }
    },
    checkLastItem(arrLenght, index) {
      return index + 1 === arrLenght ? false : true;
    },
    openContactPopup() {
      this.$emit("openContactPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer--container {
  position: relative;
  height: 100px;
  background-color: #00064b;
  padding: 59px 40px 0px 40px;
  width: 100%;
  max-width: 1440px;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.links--container {
  margin-bottom: 16px;
}

.bottom--link a {
  font-family: "Mont";
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;

  &:hover,
  &:active {
    font-weight: var(--baselink-font-weight);
  }
}

.copyright {
  font-family: "Mont";
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}

.separator {
  color: #ffffff;
  margin: 0px 15px;
}

.logo-container {
  display: grid;
  place-items: center;
  width: 210px;
  height: 52px;
  align-content: center;
}

.contact-button {
  font-family: "Mont";
  --button-font-size: 14px;
  --button-line-height: 14px;
  --button-font-weight: 600;
  --button-padding-quinary: 8px 25px;
  &:hover,
  &:active {
    --button-font-weight: 700;
  }
}

.center--links {
  display: unset;
}

.mobile-center--links {
  display: none;
}

@media (max-width: 1267px) {
  .footer--container {
    height: 100%;
    padding: 56px 40px 56px 40px;
  }
  .wrapper {
    margin-bottom: 40px;
  }
  .center--links {
    display: none;
  }

  .mobile-center--links {
    display: unset;
    text-align: start;
    .bottom--link {
      :last-child {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 1280px) {
  .logo-container {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .footer--container {
    height: 100%;
    padding: 56px 24px 56px 24px;
  }
  
  .logo-container {
    width: 150px;
  }
  .wrapper {
    margin-bottom: 47px;
  }
  .mobile-center--links {
    .bottom--link {
      display: flex;
      flex-direction: column;
      a {
        margin: 9px 0px;
        text-decoration: underline;
      }
    }
    .separator {
      display: none;
    }
    .copyright {
      margin-top: 32px;
    }
  }
}
@media (max-width: 500px) {
  .wrapper {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }

  .wrapper > * + * {
    margin-top: 40px;
  }
}
</style>
