<template>
  <Popup :closeButton="true" closeOnClickOutside @close="onClose">
    <template v-slot:header>
      <div class="header--container">
        <img :src="imgSrc" width="80" height="80" />
        <div class="contact-phone">
          <TextLabel
            customClass="left-label"
            :label="$t('homepage.contactsSection.header.leftLabel')"
          />
          <TextLabel
            customClass="right-label"
            :label="$t('homepage.contactsSection.header.contactLabel')"
          />
        </div>
        <TextLabel
          customClass="alternative-label"
          :label="$t('homepage.contactsSection.header.bottomLabel')"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="popup--container">
        <div class="info">
          <TextLabel
            customClass="text"
            :label="$t('homepage.contactsSection.title')"
          />
          <InputField
            class="border"
            :label="$t('homepage.contactsSection.name')"
            @onInput="(e) => onInput('name', e)"
            :value="getUserName"
            mode="filled"
            ref="name"
          />
          <InputField
            class="border"
            :label="$t('homepage.contactsSection.mobile')"
            @onInput="(e) => onInput('phone', e)"
            :value="getUserPhoneNumber"
            :mode="phoneMode"
            ref="phone"
          />
          <InputField
            class="border"
            :label="$t('homepage.contactsSection.email')"
            @onInput="(e) => onInput('email', e)"
            :value="getUserEmail"
            mode="email"
            ref="email"
          />
          <Dropdown
            :key="`dealer-dropdown-${Object.keys(dealer).length}`"
            :selectedOnHeader="true"
            :name="dealerDropdownLabel"
            :items="dealerOptions"
            @onSelectedItems="handleDealer"
          />
          <Indicator
            key="dealer-indicator-01"
            v-show="!validationFlags.dealer"
            :type="`error`"
            :label="dealerValidation"
            :icon="`exclamation-mark`"
          />
          <div class="checkboxex--wrapper">
            <CheckboxLabel
              :subtitle="$t('homepage.contactsSection.privacy')"
              type="primary"
              :checked.sync="necessaryCheck"
            />
            <CheckboxLabel
              :subtitle="$t('homepage.contactsSection.marketing')"
              type="primary"
              :checked.sync="marketingCheck"
            />
          </div>
          <div class="btn--container">
            <Button
              customClass="sendBtn"
              type="primary"
              :label="$t('homepage.contactsSection.btnLabel')"
              @onClick="sendContactRequest"
              :disabled="validForm"
              :class="[!validForm ? 'active-button' : '']"
            />
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import {
  Popup,
  TextLabel,
  InputField,
  Button,
  Dropdown,
  Indicator,
} from "@sc/ds-ui-component-library";
import { CheckboxLabel } from "../../molecules";
import { mapGetters } from "vuex";
import { Country } from '@/enums/country';
export default {
  name: "ContactPopup",
  components: {
    Popup,
    TextLabel,
    Button,
    InputField,
    CheckboxLabel,
    Dropdown,
    Indicator,
  },
  data() {
    return {
      openContactPopup: false,
      enableBtn: true,
      necessaryCheck: false,
      marketingCheck: false,
      dealer: [[], false],
      user: {
        name: { label: "", valid: false },
        email: { label: "", valid: false },
        phone: { label: "", valid: false },
      },
      validationFlags: {
        dealer: true,
      },
      dealerOptions: [],
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      getUserName: "UserModule/getUserName",
      getUserPhoneNumber: "UserModule/getUserPhoneNumber",
      getUserEmail: "UserModule/getUserEmail",
      getDealersDropdown: "DeliveryVehicleModule/getDealersDropdown",
    }),
    validForm() {
      if (!this.necessaryCheck || !this.dealer[1]) return true;
      return !Object.values(this.user).every((x) => x.valid === true);
    },
    imgSrc() {
      return (
        this.$t("resources.images.contactPopup") ||
        require("@/assets/contactForm/Icon_TLF.svg")
      );
    },
    dealerDropdownLabel() {
      return this.$t("homepage.contactsSection.dropdownTitle");
    },
    dealerValidation() {
      return this.$t("homepage.contactsSection.dropdownAlert");
    },
    phoneMode(){
      return process.env.VUE_APP_COUNTRY === Country.PT ? 'phone' : 'phoneES';
    }
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    sendContactRequest() {
      const newUserObj = Object.keys(this.user)
        .map((key) => {
          return { [key]: this.user[key].label };
        })
        .reduce((r, c) => Object.assign(r, c), {});

      const contactData = {
        name: newUserObj.name,
        phoneNumber: newUserObj.phone || "",
        emailAddress: newUserObj.email,
        infoCheck: this.marketingCheck,
        currentUrl: window.location.href || "",
        dealerId: this.dealer[0]?.key || "",
      };

      this.$emit("onSubmit", contactData);
    },
    onInput(input, val) {
      this.user[input].label = val[0];
      this.user[input].valid = val[1];
    },
    showLabels(ref) {
      switch (ref) {
        case "name":
          this.user.name.label = this.getUserName;
          this.user.name.valid = this.getUserName.length === 0 ? false : true;
          break;
        case "email":
          this.user.email.valid = !(
            this.$refs[ref].validateEmail(this.getUserEmail)[0] === "error"
          );
          break;
        case "phone":
          this.user.phone.valid = !(
            this.$refs[ref].validatePhone(this.getUserPhoneNumber)[0] ===
            "error"
          );
          break;
        default:
      }
    },
    validateInputs() {
      this.showLabels("name");
      this.showLabels("email");
      this.showLabels("phone");
    },
    handleDealer(e) {
      this.dealerOptions = e;
      const dealer = e.length ? e.filter((d) => d.selected) : "";
      this.dealer = [dealer.length ? dealer[0] : dealer, !!dealer.length];
      this.validationFlags.dealer = !!dealer.length;
    },
  },
  async created() {
    await this.$store.dispatch("DeliveryVehicleModule/fetchDealersDropdown");
    this.dealerOptions = this.getDealersDropdown;
  },
  mounted() {
    this.validateInputs();
  },
};
</script>

<style lang="scss" scoped>
.header--container {
  font-family: Mont;
  height: 290px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header--container > * + * {
  margin-top: 40px;
}

.header--container:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 13px solid transparent;
  border-right: 14px solid transparent;
  border-top: 15px solid var(--theme-color-quinary);
  border-bottom: 11px solid transparent;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: -24px;
}
.contact-phone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-phone > * + * {
  margin-left: 12px;
}

.left-label {
  --text-label-color: var(--theme-color-quaternary);
  --text-label-font-size: 16px;
  --text-label-line-height: 16px;
  --text-label-weight: 600;
}

.right-label {
  --text-label-color: var(--color-7);
  --text-label-font-size: 24px;
  --text-label-line-height: 24px;
  --text-label-weight: 700;
  border: 1px solid var(--theme-color-quaternary);
  border-radius: 80px;
  padding: 8px 24px;
}
.alternative-label {
  --text-label-color: var(--color-7);
  --text-label-font-size: 18px;
  --text-label-line-height: 18px;
  --text-label-weight: 700;
}
:deep(.popup) {
  border-radius: 6px;
  max-height: 660px;
  .popup-header .popup-header-right {
    width: unset;
    height: unset;
    align-items: unset;
    justify-content: unset;
    align-self: unset;
    position: absolute;
    right: 21px;

    .button {
      --button-tertiary-bg-color: transparent;
      --button-tertiary-color: var(--theme-color-quaternary);
      --button-icon-tertiary-color-hover: var(--theme-color-quaternary);
      --button-icon-tertiary-color: var(--theme-color-quaternary);
    }
  }
}
.popup--container {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 474px;
  width: 100%;
  font-family: "Mont";

  .info {
    display: flex;
    flex-direction: column;
  }

  .info > * + * {
    margin-top: 16px;
  }

  .text {
    color: var(--color-0);
  }

  .border {
    :deep .container {
      border: 1px solid #e5e5e5;
    }
  }

  .sendBtn {
    width: 100%;
    justify-content: center;
  }

  :deep .container .input--label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--color-0);
  }
}

:deep #popupTitle {
  height: max-content;
  background-color: #00064b;
  margin: 0 0 2rem 0;
  padding: 0;
}

.btn--container {
  display: flex;
  justify-content: center;
  :deep {
    .tertiary {
      font-family: Mont;
      --button-tertiary-border: none;
      --button-tertiary-bg-color: #2cfe71;

      &:hover,
      &:active {
        --button-tertiary-hover: #2cfe71;
        --button-text-color: #00064b;
        --button-tertiary-border: none;
      }
    }
  }
}

.checkboxex--wrapper {
  display: flex;
  flex-direction: column;
}

.checkboxex--wrapper > * + * {
  margin-top: 10px;
}

:deep .checkbox--content {
  .checkbox--content-subtitle {
    display: flex;
    margin-left: 6px;
    .label {
      font-family: Mont;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #b2b2b2;
    }
  }
}

:deep .checkbox {
  margin-right: unset;
  .checkbox--container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox--container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox--checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid #2cfe71;
    border-radius: 2px;
  }

  .checkbox--container:hover input ~ .checkbox--checkmark {
    background-color: transparent;
  }

  .checkbox--container input:checked ~ .checkbox--checkmark {
    border: 1px solid #2cfe71;
    border-radius: 2px;
    background-color: var(--theme-color-quaternary);
  }

  .checkbox--checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox--container input:checked ~ .checkbox--checkmark:after {
    display: block;
  }

  .checkbox--container .checkbox--checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: 1px solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

:deep(.dropdown-header) {
  &.selected .dropdown-header-inner .text-label {
    --text-label-font-size: 16px;
    --text-label-line-height: 16px;
    --text-label-weight: 600;
    --text-label-color: var(--cv-secondary);
  }
  .dropdown-header-inner .text-label {
    --text-label-font-size: 16px;
    --text-label-line-height: 16px;
    --text-label-weight: 500;
    --text-label-color: var(--color-0);
  }
}

@media (max-width: 1367px) {
  :deep(.popup) {
    max-height: 660px;
  }
}

@media (max-width: 1280px) {
  :deep(.popup) {
    border-radius: 6px;
    max-height: 660px;
    max-width: 480px;
  }
}

@media (max-width: 594px) {
  :deep(.popup) {
    border-radius: 6px;
    max-height: 560px;
    max-width: 311px;
  }
  .contact-phone {
    flex-direction: column;
  }
}
</style>
