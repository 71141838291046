const state = {
    loading: false,
  };
  
  const getters = {
    isLoading: (state) => state.loading,
  };
  
  const mutations = {
    setLoading(state, loading) {
      state.loading = loading;
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
  };
  