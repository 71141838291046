import httpClient from "./httpClient";

const RETURNS = "/users/me/retake";
const UPLOAD_FILE = "/image";
const RETAKE = "/retake";
const PHASE_2 = "/phase2/v2";
const EXTERIOR_COLORS = "/retake/exteriorColors";
const INTERIOR_COLORS = "/retake/interiors";

function getAllReturns() {
  return httpClient.get(RETURNS);
}

function getReturnDetails(id) {
  return httpClient.get(RETURNS + "/" + id);
}

function addPhoto(id, payload) {
  return httpClient.post(`${RETAKE}/${id}${UPLOAD_FILE}`, payload , 
                    { headers: { 'Content-Type': 'multipart/form-data'} });
}

function updateReturn(details) {
  return new Promise((resolve) => {
    resolve(httpClient.post(`${RETAKE}${PHASE_2}`, details));
  });
}

function deleteReturn(id) {
  return httpClient.delete(RETURNS + "/" + id);
}
function getAllColors() {
  return httpClient.get(EXTERIOR_COLORS);
}
function getInteriorColors() {
  return httpClient.get(INTERIOR_COLORS);
}

export {
  getAllReturns,
  addPhoto,
  getReturnDetails,
  deleteReturn,
  updateReturn,
  getAllColors,
  getInteriorColors
};
