import i18n from "@/i18n.js";



const defaults = {
  equipments: [
    {
      text: "ABS",
      value: "ABS",
      checked: false,
    },
    {
      text: "Bancos Aquecidos",
      value: "HeatedSeats",
      checked: false,
    },
    {
      text: "Espelhos Elétricos",
      value: "RetractableElectricMirrors",
      checked: false,
    },
    {
      text: "Rádio com CD",
      value: "AutoRadioWithCD",
      checked: false,
    },
    {
      text: "Vidros Elétricos",
      value: "ElectricFrontGlass",
      checked: false,
    },
    {
      text: "Bancos Elétricos",
      value: "ElectricallyAdjustableSeats",
      checked: false,
    },
    {
      text: "Cruise Control",
      value: "CruiseControl",
      checked: false,
    },
    {
      text: "GPS",
      value: "NavigationSystem",
      checked: false,
    },
    {
      text: "Bluetooth",
      value: "Bluetooth",
      checked: false,
    },
    {
      text: "Controlo de Estabilidade (ESP)",
      value: "StabilityControl",
      checked: false,
    },
    {
      text: "Faróis de Xénon",
      value: "XenonHeadlamps",
      checked: false,
    },
    {
      text: "Jantes em Liga Leve",
      value: "AlloyWheels",
      checked: false,
    },
    {
      text: "Teto de Abrir",
      value: "OpeningGlassRoof",
      checked: false,
    },
    {
      text: "Sensores de Parqueamento Frontais",
      value: "FrontParkingSensors",
      checked: false,
    },
    {
      text: "Sensores de Parqueamento Traseiros",
      value: "RearParkingSensors",
      checked: false,
    },
  ],
  accessories: [
    {
      text: "Livro de Instruções",
      value: "InstructionsManual",
      checked: false,
    },
    {
      text: "Livro de Revisões",
      value: "ServiceBook",
      checked: false,
    },
    {
      text: "Segunda Chave",
      value: "SecondaryKey",
      checked: false,
    },
  ],
  interiorList: [
    { value: i18n.t("returns.sections.carFeatures.interiorList.tela"), key: "tela" },
    { value: i18n.t("returns.sections.carFeatures.interiorList.cuero"), key: "cuero" }
  ],
  provenance: {
    radioGroupName: "provenance",
    radioGroupValue: "NAT",
    radioGroupList: [
      {
        label: i18n.t("returns.sections.radioGroupList.yes"),
        radioValue: "IMPORT",
        radioId: "imported-vehicle-yes",
        checked: false,
      },
      {
        label: i18n.t("returns.sections.radioGroupList.no"),
        radioValue: "NAT",
        radioId: "imported-vehicle-no",
        checked: false,
      },
    ],
  },
  generalStatus: [
    {
      title: i18n.t("returns.sections.mechanicalAndGeneralConditions.generalStatus.title"),
      subtitle: i18n.t("returns.sections.mechanicalAndGeneralConditions.generalStatus.subtitle"),
      radioGroupName: "InteriorAndExteriorConservation",
      radioGroupValue: "NONE",
      radioGroupList: [
        {
          label: i18n.t("returns.sections.radioGroupList.good"),
          radioValue: "GOOD",
          radioId: "conservation-good",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.reasonable"),
          radioValue: "AVERAG",
          radioId: "conservation-reasonable",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.bad"),
          radioValue: "POOR",
          radioId: "conservation-bad",
          checked: false,
        },
      ],
    },
  ],
  mechanicalState: [
    {
      title: i18n.t("returns.sections.mechanicalAndGeneralConditions.mechanicalState.motor.title"),
      subtitle: i18n.t("returns.sections.mechanicalAndGeneralConditions.mechanicalState.motor.subtitle"),
      radioGroupName: "EnginePerformance",
      radioGroupValue: "NONE",
      radioGroupList: [
        {
          label: i18n.t("returns.sections.radioGroupList.good"),
          radioValue: "GOOD",
          radioId: "engine-performance-good",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.reasonable"),
          radioValue: "AVERAG",
          radioId: "engine-performance-reasonable",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.bad"),
          radioValue: "POOR",
          radioId: "engine-performance-bad",
          checked: false,
        },
      ],
    },
    {
      title: i18n.t("returns.sections.mechanicalAndGeneralConditions.mechanicalState.driving.title"),
      subtitle: i18n.t("returns.sections.mechanicalAndGeneralConditions.mechanicalState.driving.subtitle"),
      radioGroupName: "DrivePerformance",
      radioGroupValue: "NONE",
      radioGroupList: [
        {
          label: i18n.t("returns.sections.radioGroupList.good"),
          radioValue: "GOOD",
          radioId: "driving-performance-good",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.reasonable"),
          radioValue: "AVERAG",
          radioId: "driving-performance-reasonable",
          checked: false,
        },
        {
          label: i18n.t("returns.sections.radioGroupList.bad"),
          radioValue: "POOR",
          radioId: "driving-performance-bad",
          checked: false,
        },
      ],
    },
  ],
};

export { defaults }; 
