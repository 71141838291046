import Vue from "vue";
import App from "./App.vue";
import "./fonts/Mont/stylesheet.css";
import "./css/main.css";
import router from './router';
import store from './store';
import getTranslation from "./api/translations";
import { LOCALES } from "./enums/locales";
import i18n from "./i18n";
import VueMeta from "vue-meta";
/* FontAwesome Component */
import { library } from "@fortawesome/fontawesome-svg-core";

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import Embed from "v-video-embed";
Vue.use(Embed);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faTimes,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);
library.add(faBars);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

Vue.use(VueToast, {
  // One of the options
  position: "top-right",
});

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
  debounceWait: 20,
  waitOnDestroyed: true,
});

const locale = process.env.VUE_APP_I18N_LOCALE;

getTranslation(locale)
  .then((res) => {
    const data = JSON.parse(res.data.data[0].translations);
    i18n.setLocaleMessage(LOCALES[locale], data);
  })
  .catch((err) => {
    console.error("Error - ", err);
  });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
