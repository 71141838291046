const initialState = {
  retake: false,
  retakeStep: 1,
  personal: false,
  return: false,
  concludeSell: false,
  concludeSellStep: 1,
};

const state = {
  ...initialState,
};

const getters = {
  isOpenRetake: (state) => state.retake,
  getRetakeStep: (state) => state.retakeStep,
  isOpenPersonal: (state) => state.personal,
  isOpenReturn: (state) => state.return,
  isOpenConcludeSell: (state) => state.concludeSell,
  getConcludeSellStep: (state) => state.concludeSellStep,
  isAnyPanelOpen: (state) => {
    return state.retake || state.personal || state.return || state.concludeSell
  }
};

const mutations = {
  toggleRetake(state) {
    state.retake = !state.retake;
    state.retakeStep = 1;
  },
  setRetakeStep(state, val) {
    state.retakeStep = val
  },
  togglePersonalPanel(state) {
    state.personal = !state.personal;
  },
  toggleReturn(state) {
    state.return = !state.return;
  },
  setToggleReturn(state, val) {
    state.return = val;
  },
  toggleConcludeSellPanel(state) {
    state.concludeSell = !state.concludeSell;
  },
  setConcludeSellStep(state, step) {
    state.concludeSellStep = step;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

