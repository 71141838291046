<template>
  <div class="session-container">
    <Link
      href="javascript:void(0)"
      @onClick="onClickHandler"
      :label="evalLabel"
      :icon="iconType"
    />
  </div>
</template>

<script>
import { Link } from "@sc/ds-ui-component-library";

export default {
  components: {
    Link,
  },
  data() {
    return {
      iconType: "chevron-right",
      windowWidth: window.innerWidth,
      retakeCount: 0,
    };
  },
  props: {
    numberOfEvals: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    evalLabel() {
      return this.windowWidth > 475
        ? this.$tc("navbar.sessionRedirect", this.numberOfEvals, {
            numberOfEvals: this.numberOfEvals,
          })
        : this.$tc("navbar.sessionRedirectMobile", this.numberOfEvals, {
            numberOfEvals: this.numberOfEvals,
          });
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResizeWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeWidth);
  },
  methods: {
    onClickHandler() {
      this.$emit("onRedirect");
    },
    onResizeWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.session-container {
  ::v-deep {
    .baselink {
      --baselink-color: #2cfe71;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding: 3px;
      text-decoration: underline;
      &:hover,
      &:active {
        font-weight: 600;
      }
    }
    .link-icon--wrapper {
      fill: #2cfe71;
    }
  }
}
</style>
