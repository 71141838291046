<template>
  <div class="basic-information">
    <div class="basic-information--title">
      <TextLabel size="normal" :label="$t('returns.sections.basic.title')" />
    </div>
    <div class="basic-information--content">
      <div class="basic-information--content-left">
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.plate')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.registration"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.brand')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.brand"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.model')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.model"
          />
        </div>
        <div class="basic-info-dealer-item">
          <TextLabel
            customClass="dealer-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.dealer')"
          />
          <Dropdown
            :key="`dealer-dropdown`"
            :selectedOnHeader="true"
            :name="$t('returns.sections.basic.labels.dealerDefault')"
            :items="getDealers"
            :disabled="true"
          />
        </div>
      </div>
      <div class="basic-information--content-right">
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.plateDate')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.registrationDate"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.fuel')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.fuel || '-'"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('returns.sections.basic.labels.kms')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="formatNonDecimalNumber(vehicle.kilometers || '-')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextLabel, Dropdown } from "@sc/ds-ui-component-library";

import { formatNonDecimalNumber } from "../../../utils/currency";
import { mapGetters } from "vuex";

export default {
  name: "BasicInformation",
  components: {
    TextLabel,
    Dropdown,
  },
  data() {
    return {
      dealersDropdown: [],
    };
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatNonDecimalNumber(n) {
      return formatNonDecimalNumber(n);
    },
  },
  computed: {
    ...mapGetters({
      getDealersDropdown: "DeliveryVehicleModule/getDealersDropdown",
      getDealerShipId: "ReturnModule/getDealerShipId",
    }),
    getDealers() {
      return this.getDealersDropdown.map((dealer) => {
        if (dealer.key == this.getDealerShipId) {
          dealer.selected = true;
        }
        return dealer;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-information {
  display: flex;
}

.basic-information--title {
  width: 33%;
  padding-right: 40px;
  text-align: left;
  --text-label-font-size: 16px;
  --text-label-line-height: 16px;
  --text-label-weight: bold;
  --text-label-color: var(--cv-black);
}

.basic-information--content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

:deep(.label span) {
  color: var(--color-2);
  font-weight: 500;
}

:deep(.label) {
  margin-bottom: 16px;
}

.basic-information--content-left,
.basic-information--content-right {
  display: flex;
  flex-direction: column;
  width: 45%;
}

:deep(.grey-label) {
  --text-label-color: var(--cv-black);
  --text-label-font-weight: 600;
  --text-label-font-size: 16px;
}

:deep(.dealer-label) {
  --text-label-color: var(--cv-black);
  --text-label-font-weight: 600;
  --text-label-font-size: 16px;
  text-align: start;
}

:deep(.dark-label) {
  --text-label-color: var(--cv-secondary);
  --text-label-weight: 600;
  --text-label-font-size: 16px;
  margin-left: 8px;
}

.basic-info-item {
  display: flex;
}

.basic-info-dealer-item {
  display: flex;
  align-items: center;

  :deep(.dropdown--wrapper) {
    width: 100%;
  }

  :deep(.dealer-label) {
    --text-label-word-break: keep-all;
  }

  & > *:last-child {
    margin-left: 9px;
  }
}

@media (max-width: 1024px) {
  .basic-information {
    flex-direction: column;
  }

  .basic-information--content-left,
  .basic-information--content-right {
    width: 100%;
  }

  .basic-information--content {
    margin-top: 24px;
    flex-direction: column;
    width: 100%;

    & > * {
      margin: 9px 0;
    }

    & > *:first-child {
      margin: 0 0 9px 0;
    }

    & > *:last-child {
      margin: 9px 0 0 0;
    }
  }
}
</style>
