<template>
  <Popup closeOnClickOutside @close="close">
    <template v-slot:body>
      <div class="popup--container">
        <div class="info">
          <TextLabel customClass="title" :label="getTitle" />
          <div class="first-step--container" v-if="isFirstStep">
            <TextLabel customClass="description" :label="descriptionLabel" />
            <div class="dealer-dropdown">
              <Dropdown
                :key="`dealer-dropdown-${Object.keys(dealer).length}`"
                :selectedOnHeader="true"
                :name="dealerDropdownLabel"
                :items="dealerOptions"
                scroll
                @onSelectedItems="handleDealer"
              />
              <Indicator
                key="dealer-indicator-01"
                v-show="!validationFlags.dealer"
                :type="`error`"
                :label="dealerValidation"
                :icon="`exclamation-mark`"
              />
            </div>
          </div>
          <InputField
            v-else
            :placeholder="$t('personalArea.popup.step2.placeholder')"
            :mode="phoneMode"
            :key="phoneInputRenderKey"
            ref="phoneInput"
            :value="getUserPhoneNumber"
            @onInput="(e) => (contactNumber = e)"
          />
        </div>
        <div class="btn--container">
          <Button
            :label="leftBtnLabel"
            @onClick="leftBtnClick"
            type="tertiary"
          />
          <Button
            customClass="rightBtnLabe"
            :label="rightBtnLabel"
            @onClick="rightBtnClick"
            :disabled="contactNumberValid"
            type="primary"
          />
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import {
  Popup,
  TextLabel,
  InputField,
  Button,
  Dropdown,
  Indicator,
} from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { LEAD_TYPES } from "../../../utils/leadTypes";
import { Country } from '@/enums/country';
export default {
  name: "SellPopup",
  components: { Popup, TextLabel, Button, InputField, Dropdown, Indicator },
  props: {},
  data() {
    return {
      phoneInputRenderKey: 0,
      contactNumber: ["", false],
      step: 1,
      validationFlags: {
        dealer: true,
      },
      dealerOptions: [],
      dealer: [[], false],
    };
  },
  async created() {
    await this.$store.dispatch("DeliveryVehicleModule/fetchDealersDropdown");
    this.dealerOptions = this.getDealersDropdown.map((dealer) => {
      if (dealer.key === this.getDealerId) {
        dealer.selected = true;
        this.dealer = [dealer, true];
      }
      return dealer;
    });
    if (this.getUserPhoneNumber) {
      this.contactNumber = [this.getUserPhoneNumber, true];
      this.step = 1;
    } else {
      this.step = 2;
    }
  },
  computed: {
    ...mapGetters({
      getUserPhoneNumber: "getUserPhoneNumber",
      getVehicleId: "DeliveryVehicleModule/getVehicleId",
      getUserName: "UserModule/getUserName",
      getUserEmail: "UserModule/getUserEmail",
      getDealersDropdown: "DeliveryVehicleModule/getDealersDropdown",
      getDealerId: "DeliveryVehicleModule/getDealerId",
    }),
    descriptionLabel() {
      return this.$tc(
        "personalArea.popup.step1.description",
        this.contactNumber,
        {
          contactNumber: this.getUserPhoneNumber || "---",
        }
      );
    },
    isFirstStep() {
      return this.step === 1;
    },
    getTitle() {
      return this.step === 1
        ? this.$t("personalArea.popup.step1.title")
        : this.$t("personalArea.popup.step2.title");
    },
    leftBtnLabel() {
      return this.step === 1
        ? this.$t("personalArea.popup.step1.leftBtnLabel")
        : this.$t("personalArea.popup.step2.leftBtnLabel");
    },
    rightBtnLabel() {
      return this.step === 1
        ? this.$t("personalArea.popup.step1.rightBtnLabel")
        : this.$t("personalArea.popup.step2.rightBtnLabel");
    },
    contactNumberValid() {
      return !this.contactNumber[1];
    },
    dealerDropdownLabel() {
      return this.$t("personalArea.concludeSellPanel.dropdownTitle");
    },
    dealerValidation() {
      return this.$t("personalArea.concludeSellPanel.dropdownTitle");
    },
    phoneMode(){
      return process.env.VUE_APP_COUNTRY === Country.PT ? 'phone' : 'phoneES';
    }
  },
  methods: {
    leftBtnClick() {
      if (!this.getUserPhoneNumber && this.step === 2) {
        this.$emit("close");
      }
      this.step === 1 ? this.step++ : this.step--;
    },
    async rightBtnClick() {
      if (this.step === 1) {
        if (!this.dealer[1]) {
          this.validationFlags.dealer = this.dealer[1];
          return;
        }
        const payload = {
          name: this.getUserName,
          phoneNumber: this.getUserPhoneNumber || "",
          emailAddress: this.getUserEmail,
          tradeInId: this.getVehicleId,
          currentUrl: window.location.href || "",
          dealerId: this.dealer[0].key,
        };
        await this.$store
          .dispatch("DeliveryVehicleModule/submitVehicleDeliveryDetails", {
            payload: payload,
            leadType: LEAD_TYPES.CONSIGNEMENT_SELL,
          })
          .then(async () => {
            //Google Tag Manager consignment sell
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "sellConsignacao",
            });
            await this.$store.dispatch("RetakeModule/storeGetAllRetakes");
            this.close();
          });
      } else {
        this.$store.commit("setPhoneNumber", this.contactNumber[0]);
        this.step--;
      }
    },
    close() {
      this.$emit("close");
    },
    handleDealer(e) {
      this.dealerOptions = e;
      const dealer = e.length ? e.filter((d) => d.selected) : "";
      this.dealer = [dealer.length ? dealer[0] : dealer, !!dealer.length];
      this.validationFlags.dealer = !!dealer.length;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #popupDescription {
  padding: 32px;
  overflow: hidden;
}
.popup--container {
  display: flex;
  flex-direction: column;
  text-align: left;
  /**
  * ! Check Width
  */
  max-width: 400px;
  width: 400px;
  // width: 100%;

  font-family: "Mont";
  .info {
    display: flex;
    flex-direction: column;
  }

  .info > * + * {
    margin-top: 16px;
  }

  .dealer-dropdown {
    margin: 20px 0px 20px 0px;

    :deep(.dropdown-menu) {
      --dropdown-menu-scroll-max-height: 120px;
    }

    :deep(.dropdown-header) {
      &.selected .dropdown-header-inner .text-label {
        --text-label-font-size: 16px;
        --text-label-line-height: 16px;
        --text-label-weight: 600;
        --text-label-color: var(--cv-secondary);
      }
      .dropdown-header-inner .text-label {
        --text-label-font-size: 16px;
        --text-label-line-height: 16px;
        --text-label-weight: 500;
        --text-label-color: var(--color-0);
      }
    }
  }

  .title {
    --text-label-color: var(--theme-color-quinary);
    --text-label-font-size: 16px;
    --text-label-line-height: 21px;
    --text-label-weight: 800;
  }
  .description {
    --text-label-color: var(--color-0);
    --text-label-font-size: 14px;
    --text-label-line-height: 18px;
    --text-label-weight: 600;
  }
  .btn--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
    ::v-deep .button {
      --button-padding: 19px 10px;
      width: 100%;
      justify-content: center;
    }
    ::v-deep .button:first-child {
      --button-tertiary-border: 1.50459px solid var(--theme-color-quinary);
    }
    ::v-deep .button:last-child .primary {
      --button-primary-bg-color: var(--theme-color-quaternary);
      --button-text-color: var(--theme-color-quinary);
      &:hover,
      &:active {
        --button-primary-hover: var(--theme-color-quinary);
        --button-text-color: var(--color-7);
        --button-primary-border: 1.50459px solid var(--theme-color-quinary);
      }
    }
  }

  .btn--container > * + * {
    margin-left: 16px;
  }

  :deep(.wrapper) {
    .container {
      border: 1px solid #e5e5e5;
      --input-container-background-color: var(--cv-white);
      --baseinput-padding-bottom: 25px;
    }
  }
}

@media (max-width: 475px) {
  .popup--container {
    width: 100%;
    .btn--container {
      flex-direction: column;
    }
    .btn--container > * + * {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
</style>
