var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mechanical-general-condition-wrapper"},[_c('div',{staticClass:"mechanical-general-condition"},[_c('div',{staticClass:"mechanical-general-content--title"},[_c('TextLabel',{attrs:{"color":"primary","size":"medium","label":_vm.$t('returns.sections.mechanicalAndGeneralConditions.title.general')}})],1),_c('div',{staticClass:"mechanical-general-condition--content"},[_c('div',{staticClass:"mechanical-general-condition--content-group"},_vm._l((_vm.getGeneralStatus),function(item,index){return _c('div',{key:index,staticClass:"radio-list"},[_c('div',{staticClass:"divider divider-bottom"}),_c('RadioGroup',{attrs:{"disabled":_vm.getReturn.submitted,"title":item.title,"subtitle":item.subtitle,"options":item.radioGroupList},on:{"onInput":function($event){return _vm.onChangeGeneralState(index, $event)}}}),_c('Indicator',{directives:[{name:"show",rawName:"v-show",value:(_vm.emptyGeneralStatus(index)),expression:"emptyGeneralStatus(index)"}],key:`general-indicator-0${index}`,attrs:{"type":`error`,"label":_vm.$t(
                'returns.sections.mechanicalAndGeneralConditions.indicators.general'
              ),"icon":`exclamation-mark`}}),(index < _vm.getMechanicalState.length - 1)?_c('div',{staticClass:"divider divider-top"}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"mechanical-general-condition"},[_c('div',{staticClass:"mechanical-general-content--title"},[_c('TextLabel',{attrs:{"color":"primary","size":"medium","label":_vm.$t(
            'returns.sections.mechanicalAndGeneralConditions.title.mechanical'
          )}})],1),_c('div',{staticClass:"mechanical-general-condition--content"},[_c('div',{staticClass:"mechanical-general-condition--content-group"},_vm._l((_vm.getMechanicalState),function(item,index){return _c('div',{key:index,staticClass:"radio-list"},[(index < _vm.getMechanicalState.length - 1)?_c('div',{staticClass:"divider divider-bottom"}):_vm._e(),_c('RadioGroup',{attrs:{"disabled":_vm.getReturn.submitted,"title":item.title,"subtitle":item.subtitle,"options":item.radioGroupList},on:{"onInput":function($event){return _vm.onChangeMechanicalState(index, $event)}}}),_c('Indicator',{directives:[{name:"show",rawName:"v-show",value:(_vm.emptyMechanicalValues(index)),expression:"emptyMechanicalValues(index)"}],key:`mechanical-indicator-0${index}`,attrs:{"type":`error`,"label":_vm.$t(
                'returns.sections.mechanicalAndGeneralConditions.indicators.mechanical'
              ),"icon":`exclamation-mark`}}),_c('div',{staticClass:"divider divider-top"})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }