<template>
  <div :style="customStyles" :class="['navbar', customClass, classes]">
    <div class="navbar--inner">
      <div class="navbar--left">
        <slot name="left"></slot>
        <div @click="toggleMenu">
          <Icon
            @click="toggleMenu"
            class="hamburguer"
            :name="isOpenNavbar ? 'times' : 'bars'"
          />
        </div>
      </div>
      <div class="navbar--center">
        <slot name="center"></slot>
      </div>
      <div class="navbar--right-1">
        <slot name="right-1"></slot>
      </div>
      <div class="navbar--right">
        <slot name="right-2"></slot>
      </div>
      <div class="mobile-menu">
        <Panel :opened="isOpenNavbar" @clickOutside="toggleMenu">
          <PanelHeader
            :subtitle="$t('navbar.innerClose')"	
            @onClosePanel="toggleMenu"
            closeButton
          />
          <PanelContent>
            <div class="navbar--content">
              <slot name="right-2" />
              <div class="divider" />
              <div class="center--container">
                <slot name="center" />
              </div>
            </div>
          </PanelContent>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "../../atoms";
import { Panel, PanelHeader, PanelContent } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

export default {
  // eslint-disable-next-line
  name: "Navbar",
  components: {
    Icon,
    Panel,
    PanelHeader,
    PanelContent,
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      isOpenNavbar: "getIsOpenNavbar",
    }),
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
  },
  methods: {
    toggleMenu() {
      this.$store.commit("toggleNavbar", document);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 80px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

  font-family: Mont !important;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.01px !important;
  line-height: 14px !important;
  text-align: left !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  max-width: 1440px;
}

.navbar .navbar--inner {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.navbar .navbar--inner .navbar--left {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .navbar--inner .navbar--left .hamburguer {
  cursor: pointer;
  position: absolute;
  top: 35%;
  bottom: 35%;
  right: 1rem;
  filter: invert(100%) sepia(12%) saturate(2%) hue-rotate(136deg)
    brightness(112%) contrast(101%);
}

.navbar .navbar--inner .navbar--center {
  display: flex;
  align-items: center;
  flex-grow: 1;
  display: none;
}

.navbar .navbar--inner .navbar--right {
  height: 100%;
  display: flex;
  align-items: center;
  display: none;
}

.reserved-area > * + * {
  margin: 0 0 0 20px;
}

@media (min-width: 1115px) {
  .navbar .navbar--inner .navbar--left .hamburguer {
    display: none;
  }
  .navbar .navbar--inner .navbar--right,
  .navbar .navbar--inner .navbar--center {
    display: flex;
  }
}

.navbar .navbar--inner .navbar--right ::v-deep .link {
  font-family: Mont !important;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.01px !important;
  line-height: 14px !important;
  text-align: left !important;
  letter-spacing: normal !important;
  line-height: normal !important;
}

@media (max-width: 1280px) {
  .navbar {
    padding: 50px 5px;
  }
}

@media (min-width: 1115px) {
  .navbar .navbar--inner .navbar--right-1 .icon--wrapper {
    margin-right: 0;
  }
  .navbar .navbar--inner .navbar--right-1 {
    margin-right: 10px;
  }
  .navbar .navbar--inner .navbar--right-1 ::v-deep .icon--wrapper:first-child {
    margin-right: 5px;
  }
}

@media (max-width: 1114px) {
  .navbar .navbar--inner .navbar--right-1 {
    margin-right: 30px;
  }
  .navbar .navbar--inner .navbar--right-1 .icon--wrapper {
    margin-right: 20px;
  }
  .navbar {
    padding: 50px 5px;
  }
  .navbar .navbar--inner .navbar--left .hamburguer {
    top: -10px;
  }
  .navbar .navbar--inner .navbar--left ::v-deep .logo-container {
    width: 150px;
  }

  .navbar .navbar--inner .navbar--right-1 ::v-deep .icon--wrapper {
    width: 33px;
    height: 33px;
  }
  .navbar
    .navbar--inner
    .navbar--right-1
    ::v-deep
    .icon--wrapper
    .navbar--favorite-icon,
  .navbar
    .navbar--inner
    .navbar--right-1
    ::v-deep
    .icon--wrapper
    .navbar--compare-icon {
    width: 22px;
    height: 22px;
  }

  ::v-deep .idgo-wrapper .logo {
    max-width: 40px;
  }

  .navbar .navbar--center .link-list ::v-deep .item a {
    font-size: 16px;
    line-height: 22px;
  }
  ::v-deep .session-dropdown {
    margin-left: -15px;
  }
}

.navbar--center .link-list ::v-deep .item {
  align-self: center;
  margin-left: 15px;
}

.icon--wrapper {
  cursor: pointer;
}

::v-deep .router-link-exact-active.router-link-active a.link,
::v-deep .link-list .item a {
  border-color: var(--theme-color-secondary);
  color: white;
}

::v-deep .link-list .item a:hover {
  font-weight: bold;
}

@media (max-width: 475px) {
  .navbar .navbar--inner .navbar--left .hamburguer {
    filter: invert(85%) sepia(75%) saturate(3847%) hue-rotate(61deg)
      brightness(102%) contrast(101%);
  }
}

@media (min-width: 768px) {
  .navbar .navbar--inner .navbar--left {
    width: auto;
  }
}

/* Mobile Menu Layout*/

.mobile-menu {
  position: absolute;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: #00064b;
  display: none;
}

.mobile-menu.active {
  top: 88px;
  left: 0px;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #00064b;
  display: unset;
}

.mobile-menu.active ::v-deep .panel {
  --panel-width: 382px;
  --panel-responsive-width: 382px;
  --panel-background-color: #00064b;
}

.mobile-menu.active
  ::v-deep
  .idgo-widget
  .idgo-wrapper
  .options
  .user
  > .dropdown {
  width: 170px;
}

.mobile-menu.active ::v-deep .panel .panel--header-divider::after {
  opacity: 0.1;
}

.mobile-menu.active ::v-deep .backdrop {
  --backdrop-background-color: #000000;
}

.mobile-menu.active ::v-deep .panel p {
  text-align: right;
  --text-label-font-size: 14px;
  --text-label-line-height: 16px;
  --text-label-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.mobile-menu.active .navbar--content {
  width: 100%;
}

.mobile-menu.active .navbar--content ::v-deep .reserved-area {
  justify-content: center;
  --link-width: 24px;
  --link-height: 24px;
  padding: 0px;
}

.mobile-menu.active
  .navbar--content
  ::v-deep
  .reserved-area
  .session-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu.active .navbar--content ::v-deep .reserved-area .baselink {
  padding: 0px;
}

.mobile-menu.active .navbar--content ::v-deep .link-list {
  flex-direction: column;
  align-items: center;
}

.mobile-menu.active .navbar--content ::v-deep .contact-button {
  margin-left: 0px;
  width: fit-content;
}

.mobile-menu.active .navbar--content .center--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:deep(.mobile-menu.active .center--container > * + *) {
  margin-top: 40px;
}

:deep(.mobile-menu.active .link-list > * + *) {
  margin-top: 40px;
}

.mobile-menu.active ::v-deep .panel .panel--header-inner .icon {
  fill: var(--color-7);
}

.mobile-menu.active .navbar--content .divider {
  margin: 40px 0;
  opacity: 0.1;
  background-color: #eaeaea;
  height: 1px;
  width: 100%;
}

@media (min-width: 640px) {
  .navbar .navbar--inner .navbar--center {
    justify-content: center;
  }
  .mobile-menu.active .navbar--right {
    flex-direction: row;
  }
}

@media (max-width: 475px) {
  .mobile-menu.active ::v-deep .panel {
    --panel-width: 319px;
  }
  .mobile-menu.active ::v-deep .panel .panel--header-inner .icon {
    fill: var(--theme-color-quaternary);
  }
  .mobile-menu.active
    .navbar--content
    ::v-deep
    .reserved-area
    .session-container
    a {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1280px) {
  :deep(.reserved-area) > * + * {
    margin: 0 0 0 20px;
  }
}
</style>
