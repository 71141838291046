<template>
  <div class="estimate-retake">
    <div class="estimate-retake-column" v-if="!weeklyLimit">
      <img :src="carLogo" alt="logo carro" class="estimate-retake-car" />
      <div>
        <TextLabel :label="labels.title.first" class="estimate-retake-title" />
        <TextLabel :label="value" class="estimate-retake-value" />
      </div>
    </div>
    <div class="estimate-retake-info" v-if="!weeklyLimit">
      <TextLabel
        :label="labels.descriptionText.first"
        class="estimate-retake-text"
      />
      <img :src="arrowLogo" alt="logo seta" class="estimate-retake-arrow" />
      <TextLabel
        :label="labels.title.second"
        class="estimate-retake-subtitle"
      />
      <TextLabel
        :label="labels.descriptionText.second"
        class="estimate-retake-text"
      />
      <Button
        class="estimate-retake-button"
        @onClick="handleNext"
        :label="labels.buttons.finalNext"
        type="primary"
        icon="chevron-right"
        withIcon
      />
    </div>
    <div class="estimate-retake-no-info" v-else>
      <TextLabel
        :label="labels.title.weeklyLimitReached"
        class="estimate-retake-title"
      />
      <div class="estimate-retake-call-me">
        <TextLabel
          :label="labels.descriptionText.weeklyLimitReached"
          class="estimate-retake-subtitle"
        />
        <Tag :label="labels.callMeNumber" :border="true"></Tag>
      </div>
      <Button
        class="estimate-retake-button"
        @onClick="handleGoHomepage"
        :label="labels.buttons.goHomepage"
        type="primary"
        icon="chevron-right"
      />
    </div>
  </div>
</template>
<script>
import { TextLabel, Tag, Button } from "@sc/ds-ui-component-library";

export default {
  name: "EstimateRetake",
  components: {
    TextLabel,
    Tag,
    Button,
  },
  props: {
    labels: { type: Object },
    value: { type: String },
    weeklyLimit: { type: Boolean },
  },
  computed: {
    carLogo() {
      return require("../../../assets/retakePanel/carro.svg");
    },
    arrowLogo() {
      return require("../../../assets/retakePanel/seta.svg");
    },
  },
  methods: {
    handleNext() {
      this.$emit("goNext");
    },
    handleGoHomepage() {
      this.$emit("goHomepage");
    },
  },
};
</script>
<style scoped lang="scss">
.estimate-retake {
  display: flex;
  flex-direction: column;
  margin-top: 32px !important;

  :deep(.estimate-retake-title) {
    margin-bottom: 16px;
  }

  .estimate-retake-column {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    img {
      margin-right: 16px;
    }
  }

  .estimate-retake-info {
    margin-left: 96px;

    .estimate-retake-arrow {
      margin: 24px 0px 8px 0px;
      width: 64px;
      height: 64px;
    }
  }
}

.estimate-retake-no-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: start;
  height: 100%;
  margin-top: 32px;

  & > * {
    margin: 44px 0;
  }

  & > *:first-child {
    margin: 0 0 0 0;
  }

  & > *:last-child {
    margin: 44px 0 0 0;
  }
}

.estimate-retake-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: start;
  height: 100%;
  margin-left: 16px;

  & > * {
    margin: 16px 0;
  }

  & > *:first-child {
    margin: 0 0 0 0;
  }

  & > *:last-child {
    margin: 16px 0 0 0;
  }
}

.estimate-retake-arrow {
  width: 64px;
  height: 64px;
}

.estimate-retake-value {
  --text-label-color: #00064b;
  --text-label-line-height: 32px;
  --text-label-font-size: 32px;
  --text-label-weight: Bold;
}

.estimate-retake-title {
  --text-label-color: black;
  --text-label-line-height: 21px;
  --text-label-font-size: 16px;
  --text-label-weight: SemiBold;
  white-space: pre-line;
}

.estimate-retake-subtitle {
  --text-label-color: #00064b;
  --text-label-line-height: 18px;
  --text-label-font-size: 18px;
  --text-label-weight: Bold;
  margin-bottom: 0;
}

.estimate-retake-text {
  --text-label-color: #848484;
  --text-label-line-height: 18.2x;
  --text-label-font-size: 14px;
}

.estimate-retake-call-me {
  --heading-small-font-size: 18px;
  --heading-small-line-height: 18px;
  --text-label-font-size: 20px;
  --text-label-line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  :deep(.tag-container) {
    padding: 8px 16px 8px 16px;
    border-radius: 80px;
    margin-left: 8px;
    --tag-border: 1px solid var(--cv-secondary);
    --text-label-color: var(--cv-secondary);
    --text-label-weight: bold;
    --text-label-font-size: 24px;
    --text-label-line-height: 24px;
  }
}

.estimate-retake-button {
  width: 258px;
  height: 56px;
  --button-justify-content: center;
}

.estimate-retake-info {
  .estimate-retake-button {
    width: 282px;
    height: 56px;
  }
}

.estimate-retake-info {
  width: 66.5%;
}

@media (max-width: 1280px) {
  .estimate-retake-call-me {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .tag-container {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  .estimate-retake-no-info > :last-child {
    margin-top: 20px;
  }

  .estimate-retake-info {
    width: 76%;
  }

  .estimate-retake-no-info {
    .estimate-retake-button {
      white-space: nowrap;
    }
  }
}

@media (max-width: 768px) {
  .panel-retake-left {
    padding-bottom: 0px;
  }

  .estimate-retake {
    .estimate-retake-column {
      img {
        margin-right: 16px;
      }
    }

    .estimate-retake-info {
      margin-left: 96px;
      width: 80%;

      .estimate-retake-arrow {
        margin: 24px 0px 8px 0px;
      }
    }
  }
}

@media (max-width: 475px) {
  .estimate-retake {
    .estimate-retake-column {
      margin-bottom: 24px;

      img {
        margin-right: 16px;
      }
    }

    .estimate-retake-no-info {
      margin-top: 0px;
    }
    .estimate-retake-info {
      margin-left: 0px;
      width: 100%;

      .estimate-retake-arrow {
        margin: 24px 0px 8px 0px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .estimate-retake-no-info {
    .estimate-retake-button {
      white-space: nowrap;
    }
  }

  .estimate-retake-call-me .estimate-retake-subtitle {
    --text-label-font-size: 16px;
    --text-label-line-height: 21px;
    --text-label-weight: 800;
  }
  .estimate-retake-info .estimate-retake-subtitle {
    --text-label-line-height: 23px;
  }
}
</style>
