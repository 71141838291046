import { vehicleDelivery, getVehicleDelivery } from "../../api/retake";
import { getDealers } from "../../api/dealers";
import { dealerNameFormat } from "../../utils/helpers";
import Vue from "vue";

const initialState = {
    dealer: {},
    allInputValid: false,
    address: "",
    zipCode: "",
    city: "",
    addressValid: false,
    zipCodeValid: false,
    cityValid: false,
    vehicleDeliveryInfo: {},
    dealerId: ""
};

const state = {
    ...initialState,
    dealersDropdown: [],
    vehicleId: null,
};

const getters = {
    getDealer: (state) => state.dealer,
    getAddress: (state) => state.address,
    getZipCode: (state) => state.zipCode,
    getCity: (state) => state.city,
    getAdressValid: (state) => state.addressValid,
    getZipCodeValid: (state) => state.zipCodeValid,
    getCityValid: (state) => state.cityValid,
    getAllInputValid: (state) => state.allInputValid,
    getDomiciliaryInputs: (state) => { return { "address": state.address, "postalCode": state.zipCode, "city": state.city } },
    getDealersDropdown: (state) => {
        let dealersDropdown = state.dealersDropdown;
        if (dealersDropdown.length !== 0) {
            dealersDropdown = dealersDropdown.map((dealer) => {
                const dealerNameTransformation = dealerNameFormat(dealer.name);
                return { key: dealer.id, value: dealerNameTransformation }
            });
        }
        return dealersDropdown;
    },
    getVehicleId: (state) => state.vehicleId,
    getVehicleDeliveryInfo: (state) => state.vehicleDeliveryInfo,
    getDealerId: (state) => state.dealerId,
};

const mutations = {
    setDealer(state, dealer) {
        state.dealer = dealer;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setZipCode(state, zipCode) {
        state.zipCode = zipCode;
    },
    setCity(state, city) {
        state.city = city;
    },
    setAddressValid(state, addressValid) {
        state.addressValid = addressValid;
    },
    setZipCodeValid(state, zipCodeValid) {
        state.zipCodeValid = zipCodeValid;
    },
    setCityValid(state, cityValid) {
        state.cityValid = cityValid;
    },
    setAllInputValid(state) {
        state.allInputValid = state.addressValid && state.zipCodeValid && state.cityValid;
    },
    setInitialState(state) {
        for (var [key, value] of Object.entries(state)) {
            value;
            state[key] = initialState[key];
        }
    },
    setDealersDropdown(state, dealers) {
        state.dealersDropdown = dealers;
    },
    setVehicleId(state, vehicleId) {
        state.vehicleId = vehicleId;
    },
    setVehicleDeliveryInfo(state, vehicleDeliveryInfo) {
        state.vehicleDeliveryInfo = vehicleDeliveryInfo;
    },
    setDealerId(state, dealerId) {
        state.dealerId = dealerId;
    },
};

const actions = {
    cleanInfo({ commit }) {
        commit("setInitialState");
    },
    async submitVehicleDeliveryDetails({ commit }, payload) {
        commit("SpinnerModule/setLoading", true, { root: true });
        try {
            const response = await vehicleDelivery(payload.payload, payload.leadType);
            response.code === "ERR_BAD_REQUEST" ?
                Vue.$toast.error({
                    message:
                        "Lamentamos mas ocorreu um erro a efetuar o seu registo, por favor tente novamente mais tarde",
                    dismissible: true,
                    type: "error",
                }) : Vue.$toast.open({
                    message:
                        "Concluido",
                    dismissible: true,
                    type: "info",
                });
        } catch (e) {
            commit("SpinnerModule/setLoading", false, { root: true });
            Vue.$toast.open({
                message:
                    "Lamentamos mas ocorreu um erro a efetuar o seu registo, por favor tente novamente mais tarde",
                dismissible: true,
                type: "error",
            });
        } finally {
            commit("SpinnerModule/setLoading", false, { root: true });
        }
    },
    async fetchDealersDropdown({ commit }) {
        try {
            commit("SpinnerModule/setLoading", true, { root: true });
            const response = await getDealers();
            commit("setDealersDropdown", response.data.data);
        } catch (e) {
            commit(
                "AlertModule/setAlert",
                {
                    message: e.message,
                    type: "error",
                    display: true,
                    global: true,
                },
                { root: true }
            );
        } finally {
            commit("SpinnerModule/setLoading", false, { root: true });
        }
    },
    async fetchDeliveryVehicleData({ commit, state }) {
        commit("SpinnerModule/setLoading", true, { root: true });
        try {
            const vehicleId = state.vehicleId;
            const response = await getVehicleDelivery(vehicleId);
            response.data.data.zipCode = response.data?.data?.postalCode;
            delete response.data?.data?.postalCode;
            commit("setVehicleDeliveryInfo", response.data.data);
        } catch (e) {
            commit("SpinnerModule/setLoading", false, { root: true });
        } finally {
            commit("SpinnerModule/setLoading", false, { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
