<template>
  <Popup closeOnClickOutside @close="onClose">
    <template v-slot:body>
      <div class="popup--container">
        <div class="info">
          <TextLabel customClass="title" :label="labels.title" />
          <TextLabel
            v-if="labels.description"
            customClass="description"
            :label="labels.description"
          />
          <slot></slot>
        </div>
        <div class="btn--container">
          <Button
            :label="labels.leftBtnLabel"
            @onClick="onCancel"
            type="secondary"
          />
          <Button
            customClass="rightBtnLabe"
            :label="labels.rightBtnLabel"
            @onClick="onConfirm"
            type="primary"
          />
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { Popup, TextLabel, Button } from "@sc/ds-ui-component-library";
export default {
  name: "ExitPopup",
  components: { Popup, TextLabel, Button },
  props: {
    labels: {
      type: Object,
    },
  },
  methods: {
    onCancel() {
      this.$emit("onCancel");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #popupDescription {
  padding: 32px;
}

:deep(.popup) {
  border-radius: 6px;
}

.popup--container {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 378px;
  width: 378px;
  font-family: "Mont";

  .info {
    display: flex;
    flex-direction: column;
  }

  .info > * + * {
    margin-top: 16px;
  }

  .title {
    --text-label-color: var(--theme-color-quinary);
    --text-label-font-size: 16px;
    --text-label-line-height: 21px;
    --text-label-weight: 800;
  }

  .description {
    --text-label-color: var(--color-0);
    --text-label-font-size: 14px;
    --text-label-line-height: 18px;
    --text-label-weight: 600;
  }
}

.btn--container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;

  :deep(.button) {
    width: 100%;
    --button-justify-content: center;
  }
}

.btn--container > * + * {
  margin-left: 16px;
}

@media (max-width: 768px) {
  .popup--container {
    width: calc(100vw - 120px);
  }
  .btn--container {
    --button-justify-content: center;

    ::v-deep .button {
      --button-padding: 19px 10px;
    }
  }
}

@media (max-width: 360px) {
  .btn--container {
    flex-direction: column;
  }
  .btn--container > * + * {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
