<template>
  <Section id="como-funciona">
    <Heading
      class="how-it-work--title"
      tag="h2"
      :label="$t('homepage.generalSwiper.title')"
    />
    <div class="cards-swiper--container">
      <GeneralCardsBlock :cards="cards" />
    </div>
  </Section>
</template>

<script>
import {
  Section,
  Heading,
  GeneralCardsBlock,
} from "@sc/ds-ui-component-library";
export default {
  components: {
    Section,
    Heading,
    GeneralCardsBlock,
  },
  computed: {
    cards() {
      return [
        {
          image:
            this.$t("resources.images.homepage.generalSwiper1") ||
            require("@/assets/homepageSlider/cron.svg"),
          title: this.$t("homepage.generalSwiper.1.title"),
        },
        {
          image:
            this.$t("resources.images.homepage.generalSwiper2") ||
            require("@/assets/homepageSlider/suporte-24-horas.svg"),
          title: this.$t("homepage.generalSwiper.2.title"),
        },
        {
          image:
            this.$t("resources.images.homepage.generalSwiper3") ||
            require("@/assets/homepageSlider/construcao.svg"),
          title: this.$t("homepage.generalSwiper.3.title"),
        },
        {
          image:
            this.$t("resources.images.homepage.generalSwiper4") ||
            require("@/assets/homepageSlider/renda.svg"),
          title: this.$t("homepage.generalSwiper.4.title"),
        },
        {
          image:
            this.$t("resources.images.homepage.generalSwiper5") ||
            require("@/assets/homepageSlider/escudo.svg"),
          title: this.$t("homepage.generalSwiper.5.title"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#como-funciona {
  --section-bg-color: #ffffff;
  --gc-b-gap: 20px;
  --section-padding: 30px 0px 120px 0px;
}

.cards-swiper--container {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

.how-it-work--title {
  --heading-large-font-size: 28px;
  --heading-large-line-height: 28px;
  --heading-color: var(--cv-secondary);
}

@media (max-width: 1440px) {
  #como-funciona {
    --section-padding: 30px 90px 90px 90px;
  }
  .cards-swiper--container {
    margin-top: 56px;
    ::v-deep {
      .container {
        .information {
          padding: 0px 0px;
        }
      }
    }
  }
}

@media (max-width: 896px) {
  #como-funciona {
    --section-padding: 30px 40px 90px 40px;
    --mobile-gc-c-max-width: 150px;
  }
  ::v-deep .heading {
    --heading-large-font-size: 24px;
    --heading-large-line-height: 24px;
    --heading-weight: 800;
  }

  ::v-deep .general-cards--container {
    padding: 16px;
  }
}

@media (max-width: 475px) {
  #como-funciona {
    --section-padding: 20px 40px 70px 40px;
  }
}
</style>
