<template>
  <div class="page">
    <Hero @onSubmitPlate="handlePlate" />
    <div class="hp-content">
      <div class="hp-wrapper">
        <TrustPilotMini
          :businessunitId="truspilot_id"
          :trustpilotHref="trustpilot_url"
          :locale="locale"
        />
        <GeneralSwiper />
        <EmbedVideo :content="videoContent" />
        <TrustPilotCarousel
          :businessunitId="truspilot_id"
          :trustpilotHref="trustpilot_url"
          :locale="locale"
        />
        <FaqsSection @onToggleFaq="onToggleFaq" />
      </div>
    </div>
    
  </div>
</template>

<script>
import {
  Hero,
  TrustPilotMini,
  TrustPilotCarousel,
  GeneralSwiper,
  FaqsSection,
} from "../../organisms";

import { EmbedVideo } from "@sc/ds-ui-component-library";
import { PAGES } from "../../../enums/pages";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Hero,
    TrustPilotMini,
    EmbedVideo,
    TrustPilotCarousel,
    GeneralSwiper,
    FaqsSection,
  },
  metaInfo() {
    return {
      title: this.$t("meta.homepage.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.homepage.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.homepage.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.homepage.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.homepage.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.homepage.image"),
        },
      ],
    };
  },
  data() {
    return {
      trustpilot_url: this.$t("homepage.trustpilot.href"),
      truspilot_id: process.env.VUE_APP_TRUSTPILOT_BUSINESSUNIT_ID,
      locale: process.env.VUE_APP_TRUSTPILOT_LOCALE,
    };
  },
  computed: {
    ...mapGetters({
      isOpenRetakePanel: "PanelModule/isOpenRetake",
      getRetakeStep: "PanelModule/getRetakeStep",
    }),
    videoContent() {
      return {
        title: this.$t("homepage.videoContent.title"),
        description: this.$t("homepage.videoContent.description"),
        videoSrc: this.$t("homepage.videoContent.videoSrc"),
      };
    },
  },
  methods: {
    ...mapMutations({
      toggleRetake: "PanelModule/toggleRetake",
      setRetakeStep: "PanelModule/setRetakeStep",
    }),
    handlePlate(plate) {
      this.$store.commit("RetakeModule/setPlate", plate);
      this.$store.dispatch("RetakeModule/storeVehicleByPlate");
      this.toggleRetake();
      this.isOpenRetakePanel
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
    onClosedPanel() {
      document.body.classList.remove("no-scroll");
      let caetanoGoWidget = document.querySelector(".caetano-go-widget");
      caetanoGoWidget.style.zIndex = "10";
    },
    onToggleFaq(faq) {
      if (faq?.open) {
        const footprintEvent = {
          from: PAGES.HOME,
          title: faq?.title,
        };
        registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  font-family: "Mont";
  --section-padding: 112px 155px;
  z-index: 2;
}

::v-deep #video-section {
  --section-padding: 0px 155px;
}

#section-mini {
  --section-padding: 52px;
}

#section-1 :deep(.container) {
  border: none;
}

.hp-content {
  display: flex;
  justify-content: center;
  background-color: var(--cv-white);
}

.hp-wrapper {
  width: 100%;
  max-width: 1380px;
}

@media (max-width: 1680px) {
  #section-mini {
    --section-padding: 52px;
  }
}

@media (max-width: 1530px) {
  ::v-deep #video-section {
    --section-padding: 0px 70px;
  }
}

@media (max-width: 1367px) {
  #section-mini {
    --section-padding: 20px;
  }
  ::v-deep #video-section {
    --section-padding: 0px 30px;
  }
}

@media (max-width: 1280px) {
  #section-mini {
    --section-padding: 28px;
  }
  ::v-deep #video-section {
    --section-padding: 0px 30px;
  }
}

@media (max-width: 896px) {
  #section-mini {
    padding-bottom: 16px;
  }
  ::v-deep #video-section {
    --section-padding: 0px 30px;
    // title
    --ev-c-title-font-size: 24px;
    --ev-c-title-line-height: 28px;
    --ev-c-title-font-weight: 800;
    // subtitle
    --ev-c-description-font-size: 16px;
    --ev-c-description-line-height: 21px;
    --ev-c-description-font-weight: 600;
  }
}

@media (max-width: 475px) {
  ::v-deep #video-section {
    --ev-c-video-iframe-height: 184px;
    // title
    --ev-c-title-font-size: 24px;
    --ev-c-title-line-height: 21px;
    --ev-c-title-font-weight: 600;
    // subtitle
    --ev-c-description-font-size: 16px;
    --ev-c-description-line-height: 21px;
    --ev-c-description-font-weight: 600;
  }
}
</style>
