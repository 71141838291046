import httpClient from "./httpClient";
import { LEAD_TYPES } from "../utils/leadTypes";

const VEHICLE_BY_PLATE = "/retake/plate/v2/";
const GET_ALL_BRANDS = "/brands";
const GET_ALL_MODELS = "/brands/{id}/models/v2?year=";
const GET_VALUATION = "/retake/phase1/v2";
const SAVE_RETAKE = "/users/retake/";
const RETAKE_COUNT = "/users/me/retakeCount";
const USER_RETURNS = "/users/me/retake";
const GET_VEHICLE_DELIVERY = "/retake/getDeliveryDetails/";
const VEHICLE_LEAD = "retake/";
const VEHICLE_DELIVERY = "submitVehicleDelivery";
const VEHICLE_CONSIGNEMENT = "submitConsignment";
const GET_ALL_VERSIONS = "/brands/{id}/models/v2/{modelId}/version?year=";

function getAllUserReturns() {
  return httpClient.get(USER_RETURNS);
}

function getVehicleByPlate(plate) {
  return httpClient.get(VEHICLE_BY_PLATE + plate);
}

function getBrands() {
  return httpClient.get(GET_ALL_BRANDS);
}

function getModels(brand, year) {
  return httpClient.get(GET_ALL_MODELS.replace("{id}", brand) + year);
}

function getVersions(brand, modelId, year) {
  return httpClient.get(
    GET_ALL_VERSIONS.replace("{id}", brand).replace("{modelId}", modelId) + year
  );
}

function getValuation(valuationDetails) {
  return httpClient.post(GET_VALUATION, valuationDetails);
}

function saveValuation({ retakeId }) {
  return httpClient.post(SAVE_RETAKE, retakeId);
}

function getCountRetake() {
  return httpClient.get(RETAKE_COUNT);
}

function deleteRetake(id) {
  return httpClient.delete(USER_RETURNS + "/" + id);
}

function vehicleDelivery(payload, leadType) {
  const sellType = leadType === LEAD_TYPES.CONSIGNEMENT_SELL ? VEHICLE_CONSIGNEMENT : VEHICLE_DELIVERY;
  return httpClient.post(VEHICLE_LEAD + sellType, payload);
}

function getVehicleDelivery(vehicleId) {
  return httpClient.get(GET_VEHICLE_DELIVERY + vehicleId);
}

export { getAllUserReturns, getVehicleByPlate, getBrands, getModels,getVersions, getValuation, saveValuation, getCountRetake, deleteRetake, vehicleDelivery, getVehicleDelivery };
