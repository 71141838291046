import httpClient from "./httpClient";

const TRANSLATIONS = "/translations";

function getTranslation(locale) {
  return httpClient.get(TRANSLATIONS, {
    params: {
      companyId: process.env.VUE_APP_COMPANY_ID,
      locale: locale,
    },
    headers: {
      Authorization: ''
    }
  });
}

export default getTranslation;
