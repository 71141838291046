const initialState = () => ({
  name: "",
  email: "",
  phone: "",
  validUser: {
    name: false,
    email: false,
    phone: false,
  },
});

const getters = {
  getPersonal: (state) => state,
  getValidUser: (state) => Object.values(state.validUser).every(Boolean),
  getUserName: (state) => state.name,
  getUserEmail: (state) => state.email,
  getUserPhoneNumber: (state) => state.phone,
};

const mutations = {
  setField(state, [field, value]) {
    state = {
      ...state,
      [field]: value,
    };
  },
  setName(state, [val, valid]) {
    state.name = val;
    state.validUser.name = valid;
  },
  setEmail(state, [val, valid]) {
    state.email = val;
    state.validUser.email = valid;
  },
  setPhone(state, [val, valid]) {
    state.phone = val;
    state.validUser.phone = valid;
  },
};

const actions = {
  cleanUser({ commit }) {
    commit("setName", ["", false]);
    commit("setEmail", ["", false]);
    commit("setPhone", ["", false]);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
