<template>
  <div class="mechanical-general-condition-wrapper">
    <div class="mechanical-general-condition">
      <div class="mechanical-general-content--title">
        <TextLabel
          color="primary"
          size="medium"
          :label="
            $t('returns.sections.mechanicalAndGeneralConditions.title.general')
          "
        >
        </TextLabel>
      </div>
      <div class="mechanical-general-condition--content">
        <div class="mechanical-general-condition--content-group">
          <div
            class="radio-list"
            v-for="(item, index) in getGeneralStatus"
            :key="index"
          >
            <div class="divider divider-bottom" />
            <RadioGroup
              :disabled="getReturn.submitted"
              :title="item.title"
              :subtitle="item.subtitle"
              :options="item.radioGroupList"
              @onInput="onChangeGeneralState(index, $event)"
            />
            <Indicator
              :key="`general-indicator-0${index}`"
              v-show="emptyGeneralStatus(index)"
              :type="`error`"
              :label="
                $t(
                  'returns.sections.mechanicalAndGeneralConditions.indicators.general'
                )
              "
              :icon="`exclamation-mark`"
            />
            <div
              v-if="index < getMechanicalState.length - 1"
              class="divider divider-top"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mechanical-general-condition">
      <div class="mechanical-general-content--title">
        <TextLabel
          color="primary"
          size="medium"
          :label="
            $t(
              'returns.sections.mechanicalAndGeneralConditions.title.mechanical'
            )
          "
        >
        </TextLabel>
      </div>
      <div class="mechanical-general-condition--content">
        <div class="mechanical-general-condition--content-group">
          <div
            class="radio-list"
            v-for="(item, index) in getMechanicalState"
            :key="index"
          >
            <div
              v-if="index < getMechanicalState.length - 1"
              class="divider divider-bottom"
            />
            <RadioGroup
              :disabled="getReturn.submitted"
              :title="item.title"
              :subtitle="item.subtitle"
              :options="item.radioGroupList"
              @onInput="onChangeMechanicalState(index, $event)"
            />
            <Indicator
              :key="`mechanical-indicator-0${index}`"
              v-show="emptyMechanicalValues(index)"
              :type="`error`"
              :label="
                $t(
                  'returns.sections.mechanicalAndGeneralConditions.indicators.mechanical'
                )
              "
              :icon="`exclamation-mark`"
            />
            <div class="divider divider-top" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RadioGroup, TextLabel, Indicator } from "@sc/ds-ui-component-library";
export default {
  name: "MechanicalAndGeneralCondition",
  components: {
    TextLabel,
    RadioGroup,
    Indicator,
  },
  methods: {
    onChangeMechanicalState(rad, event) {
      this.$store.commit("ReturnModule/setMechanicalState", {
        rad,
        value: event,
      });
    },
    onChangeGeneralState(rad, event) {
      this.$store.commit("ReturnModule/setGeneralStatus", {
        rad,
        value: event,
      });
    },
    emptyMechanicalValues(index) {
      return (
        !this.getMechanicalState[index].radioGroupList.some(
          (elem) => !!elem.checked
        ) && this.hasSubmitted
      );
    },
    emptyGeneralStatus(index) {
      return (
        !this.getGeneralStatus[index].radioGroupList.some(
          (elem) => !!elem.checked
        ) && this.hasSubmitted
      );
    },
  },
  computed: {
    ...mapGetters({
      getReturn: "ReturnModule/getReturn",
      getMechanicalState: "ReturnModule/getMechanicalState",
      getGeneralStatus: "ReturnModule/getGeneralStatus",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mechanical-general-condition {
  display: flex;
}

.mechanical-general-content--title {
  width: 33%;
  padding-right: 40px;
  text-align: left;
  --text-label-font-size: 16px;
  --text-label-line-height: 16px;
  --text-label-weight: bold;
  --text-label-color: var(--cv-black);
}

.mechanical-general-condition--subtitle {
  width: 100%;
}

.mechanical-general-condition--subtitle .label {
  font-size: 14px;
  font-family: Mont;
  font-weight: 400;
  line-height: 18px;
}

.mechanical-general-condition--content,
.mechanical-general-condition--content-group,
.mechanical-general-condition--content-group {
  width: 100%;
}

:deep(.choice-group-title),
:deep(.choice-group-subtitle) {
  text-align: left;
}

.mechanical-general-condition--content-group-title {
  display: grid;
  margin-bottom: 1.25rem;
}

@media (min-width: 380px) {
  .mechanical-general-condition--content-group-radio {
    display: flex;
  }
}

::v-deep .radio-button-label {
  margin-right: 2.5rem;
}

.radio-list {
  margin-bottom: 1.75rem;
}

::v-deep .radio-button-label--title .label {
  color: rgba(91, 141, 244, 1);
  font-size: 14px;
}

.mechanical-general-condition--content-group-title .label {
  font-size: 14px;
}

.mechanical-general-condition--content-right {
  margin-top: 2.5rem;
}

.group-title {
  font-weight: 500;
  font-family: Mont;
  font-size: 14px;
}

.group-subtitle {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-2);
}

.subtitle {
  margin-top: 16px;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-2);
  margin-bottom: 40px;
}

::v-deep .dark-label {
  color: var(--color-1);
}

.divider {
  height: 1px;
  background-color: var(--color-5);

  &.divider-top {
    margin-top: 24px;
  }

  &.divider-bottom {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .mechanical-general-condition {
    flex-direction: column;
  }

  .mechanical-general-content--title {
    margin-bottom: 24px;
  }
}
</style>
