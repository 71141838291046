import { defaults } from "../../utils/phase2Defaults";
import Vue from "vue";
import i18n from "@/i18n.js";

import {
  getReturnDetails,
  updateReturn,
  addPhoto,
  deleteReturn,
  getAllColors,
  getInteriorColors
} from "../../api/returns.js";


function changeDataBasedOnBackend(arr, base) {
  const parsedArr = createDuplicatedDataForCheckboxes(arr);
  return base?.map((it, idx) => ({
    ...it,
    ...parsedArr[idx],
  }));
}

function createDuplicatedDataForCheckboxes(arr) {
  return arr?.map(({ radioGroupName, radioGroupValue }) => ({
    radioGroupName,
    radioGroupValue,
    radioGroupList: [
      {
        label: i18n.t("returns.sections.radioGroupList.good"),
        radioValue: "GOOD",
        radioId: "interiors-good",
        checked: "GOOD" === radioGroupValue,
      },
      {
        label: i18n.t("returns.sections.radioGroupList.reasonable"),
        radioValue: "AVERAG",
        radioId: "interiors-reasonable",
        checked: "AVERAG" === radioGroupValue,
      },
      {
        label: i18n.t("returns.sections.radioGroupList.bad"),
        radioValue: "POOR",
        radioId: "interiors-bad",
        checked: "POOR" === radioGroupValue,
      },
    ],
  }));
}

const initialState = {
  id: "",
  dealerShipId: "",
  returnObj: {
    tradeInPayload: {
      features: {
        provenance: "",
        externalColor: "",
        cabinTrimInlay: "",
      },
      images: [],
    },
  },
  // errors
  errorsReturns: [],
  // for loading spinner
  loadingReturn: false,
  additionInfo: {},
  ...defaults,
  externalColorList: [],
};

const state = {
  ...initialState,
};

const getters = {
  getReturnId: (state) => {
    return state.id;
  },
  getReturn: (state) => {
    return state.returnObj;
  },
  getFeatures: (state) => state.returnObj?.tradeInPayload?.features,
  getGeneralStatusValue: (state) => state.generalStatusValue,
  getGeneralStatus: (state) => state.generalStatus,
  getMechanicalStateValues: (state) => state.mechanicalStateValue,
  getMechanicalState: (state) => state.mechanicalState,
  getExternalColorList: (state) => state.externalColorList,
  getProvenance: (state) => state.provenance,
  getVehicleOperation: (state) => state.vehicleOperation,
  getAccessories: (state) => state.accessories,
  getEquipments: (state) => state.equipments,
  getInteriorList: (state) => state.interiorList,
  getReturnKilometers: (state) => state.returnObj?.kilometers,
  getReturnLicensePlate: (state) => {
    return state.returns[0]?.licensePlate;
  },
  getReturnImages: (state) => state.returnObj?.phaseTwoDTOV3?.files,
  getReturnImagesPhaseTwoV3: (state) => state.returnObj?.phaseTwoDTOV3?.files,
  getAdditionInfo: (state) => state.additionInfo,
  getCarculatorId: (state) => state.returnObj.carculatorId,
  getDealerShipId: (state) => state.dealerShipId,
};

const actions = {
  async storeReturnDetails({ commit, dispatch }, id) {
    commit("SpinnerModule/setLoading", true, { root: true });
    let response = null;

    try {
      response = await getReturnDetails(id);
      commit("setReturn", response.data.data);
      dispatch("processFeatures", response.data.data?.tradeInPayload?.features);
      commit("setDealerShipId", response.data?.data?.dealerId);
      commit(
        "processMechanicalStateList",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.mechanicalStateList
      );
      commit(
        "processGeneralStatus",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.generalStatusList
      );
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("SpinnerModule/setLoading", false, { root: true });
    }
    commit("SpinnerModule/setLoading", false, { root: true });
    return response;
  },
  async storeUpdateReturnDetails({ commit }, returnDetails) {
    try {
      commit("SpinnerModule/setLoading", true, { root: true });
      //
      // !! - required
      //
      // - additionalInformation:
      //    - vehicleOperation (financing)    !!
      //    - accessories
      // - features
      //    - airConditiong
      //    - bcaid                           !!
      //    - cabintriminlay
      //    - equipments
      //    - evaluation (adjustedValuation)  !!
      //    - externalColor                   !!
      //    - internalColor                   !!
      //    - kilometers                      !!
      //    - plate                           !!
      //    - plateDate                       !!
      //    - tradeInId                       !!
      // - mechanicalAndGeneralStatus         !!
      //    - generalStatusList
      //    - mechanicalStateList
      // - submitted
      //
      // **Extra**
      // - files (format?)
      //
      var res = await updateReturn(returnDetails);
      if (res.response?.status && res.response?.status == 500) {
        return Promise.reject();
      }
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject();
    } finally {
      commit("SpinnerModule/setLoading", false, { root: true });
    }
  },
  async storeAddPhoto({ commit }, { id, file }) {
      const response = await addPhoto(id, file);
      if (response.status === 404 || response.status === 500) {
        commit("setErrorsReturns", response.data.message);
      } else {
        // commit("setAddPhoto", response.data.data);
        state.errorsReturns = [];
      }
      return response;
  },
  async storeDeleteReturn({ commit, dispatch }, id) {
    commit("SpinnerModule/setLoading", true, { root: true });

    try {
      await deleteReturn(id);
      dispatch("RetakeModule/storeGetAllRetakes");
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("SpinnerModule/setLoading", false, { root: true });
  },
  async storeColors({ commit }) {
    commit("SpinnerModule/setLoading", true, { root: true });
    try {
        const response = await getAllColors();
        commit("setColors", response.data.data)
    }catch (e){
      console.log("")
    }finally {
        commit("SpinnerModule/setLoading", false, { root: true });
    }
  },
  async storeInterior({ commit }) {
    commit("SpinnerModule/setLoading", true, { root: true });
    try {
        const response = await getInteriorColors();
        commit("setInterior", response.data.data)
    }catch (e){
      console.log("")
    }finally {
        commit("SpinnerModule/setLoading", false, { root: true });
    }
  },

  processFeatures({ commit }, features) {
    commit("setProvenance", features?.provenance || "");
  },
  processAddInfo({ commit }, additionalInformation) {
    commit("processVehicleOperation", additionalInformation?.vehicleOperation);
    commit("processAcessories", additionalInformation?.accessories);
    commit("setAdditionalInfo", additionalInformation);
  },
  cleanReturn({ commit }) {
    commit("setInitialState");
  },
};

const mutations = {
  setInitialState(state) {
    for (var [key, value] of Object.entries(state)) {
      value;
      state[key] = initialState[key];
    }
  },
  setReturnId(state, id) {
    state.id = id;
  },
  setMechanicalState(state, { rad, value }) {
    state.mechanicalState[rad].radioGroupList = value;

    state.mechanicalState[rad].radioGroupValue = value.find(
      (e) => e.checked
    )?.radioValue;
  },
  setGeneralStatus(state, { rad, value }) {
    state.generalStatus[rad].radioGroupList = value;

    state.generalStatus[rad].radioGroupValue = value.find(
      (e) => e.checked
    )?.radioValue;
  },
  setProvenance(state, provenance) {
    state.returnObj.tradeInPayload.features.provenance = provenance;
    state.provenance.radioGroupValue = provenance;
    state.provenance.radioGroupList = state.provenance.radioGroupList.map((p) =>
      p.radioValue === provenance
        ? { ...p, checked: true }
        : { ...p, checked: false }
    );
  },
  processGeneralStatus(state, list) {
    state.generalStatus = changeDataBasedOnBackend(list, state.generalStatus);
  },
  processMechanicalStateList(state, list) {
    state.mechanicalState = changeDataBasedOnBackend(
      list,
      state.mechanicalState
    );
  },
  processVehicleOperation(state, list) {
    state.vehicleOperation = state.vehicleOperation.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
  },
  processAcessories(state, list) {
    state.accessories = state.accessories.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
  },
  processEquipments(state, list) {
    state.equipments = state.equipments.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
  },
  processFeatures(state, features) {
    state.features = features;
  },
  setEquipments(state, [equipment, value]) {
    const eqs = state.returnObj?.tradeInPayload?.features?.equipments;
    if (eqs) {
      state.returnObj.tradeInPayload.features.equipments = eqs.map((e) =>
        e.value !== equipment
          ? e
          : {
            ...e,
            checked: value,
          }
      );
    }
  },
  setPhoto(state, file) {
    state.returnObj.tradeInPayload.images.push(file);
  },
  setInteriorColor(state, val) {
    state.returnObj.tradeInPayload.features.cabinTrimInlay = val.value;
    state.returnObj.tradeInPayload.features.cabinTrimInlayId = val.key;
    state.interiorList = state.interiorList.length
      ? state.interiorList.map((el) => {
        return {
          key: el.key,
          value: el.value,
          selected: val.key === el.key,
        };
      })
      : [];
  },
  setExternalColor(state, val) {
    state.returnObj.tradeInPayload.features.externalColor = val.value;
    state.returnObj.tradeInPayload.features.externalColorId = val.key;
    state.externalColorList = state.externalColorList.length
      ? state.externalColorList.map((el) => {
        return {
          key: el.key,
          value: el.value,
          selected: val.key === el.key,
        };
      })
      : [];
  },
  setKilometers(state, val) {
    state.returnObj.tradeInPayload.features.kilometers = Number(val);
  },
  setReturnKilometers(state, val) {
    state.returnObj.kilometers = val;
  },
  setAllReturns(state, returns) {
    state.returns = returns;
  },
  setReturn(state, returnObj) {
    try {
      if (!returnObj.tradeInPayload) {
        returnObj.tradeInPayload = {
          mechanicalAndGeneralStatus: {
            generalStatusList: [],
            mechanicalStateList: [],
          },
          additionalInformation: { vehicleOperation: [] },
          features: {
            provenance: "",
            externalColor: "",
            cabinTrimInlay: "",
          },
          images: [],
        };
      }
      if (!returnObj.tradeInPayload.images) {
        returnObj.tradeInPayload.images = [];
      }
      // clear file content to prevent crash in the presence of large images
      if (returnObj.tradeInPayload.images && returnObj.tradeInPayload.images.length !== 0) {
        returnObj.tradeInPayload.images = returnObj.tradeInPayload.images.map(element => {
          element.file.content = "";
          return { ...element }
        });
      }

      // forces Vue to acknowledge a change in the object  - enables reactivity
      Vue.set(state, "returnObj", returnObj);
    }
    catch (e) {
      console.log("setReturn", e);
    }

  },
  setReturnByIndex(state, index) {
    state.returnObj = state.returns[index];
  },
  setErrorsReturns(state, errors) {
    state.errorsReturns = errors;
  },
  setLoadingReturns(state, loading) {
    state.loadingReturn = loading;
  },
  setFeatures(state, features) {
    state.features = features;
  },
  setAdditionalInfo(state, additionInfo) {
    state.additionInfo = additionInfo;
  },
  setDealerShipId(state, dealerShipId) {
    state.dealerShipId = dealerShipId;
  },
  setColors(state, colors) {
    const exteriorColor = state.returnObj.phaseTwoDTOV3?.exteriorColor
    state.externalColorList = colors.map(colorObj => {
      return {
        key: colorObj.value,
        value: colorObj.label,
        selected: exteriorColor === colorObj.label || exteriorColor === colorObj.value
      }
    })
  },
  setInterior(state, colors) {
    const interiorColor = state.returnObj.phaseTwoDTOV3.interiorColor
    state.interiorList = colors.map(colorObj => {
      return {
        key: colorObj.value,
        value: colorObj.label,
        selected: interiorColor === colorObj.label || interiorColor === colorObj.value
      }
    })
  },
  formatPlateDate(state) {
    const dataFormatted =
      state.returnObj.year + "-" + state.returnObj.month + "-01";
    state.returnObj.plateDate = dataFormatted;
    state.returnObj.tradeInPayload.features.plateDate = dataFormatted;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
