import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import authentication from "./modules/authentication";
import UserModule from "./modules/userModule";
import PanelModule from "./modules/panelModule";
import RetakeModule from "./modules/retakeModule";
import ReturnModule from "./modules/returnModule";
import SpinnerModule from "./modules/spinnerModule";
import AlertModule from "./modules/alertModule";
import DeliveryVehicleModule from "./modules/deliveryVehicleModule";

Vue.use(Vuex);

const unwantedMutations = ["someMutationThatGetsCalledTooOften"];

const vuexSessionStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    UserModule: state.UserModule,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: (mutation) => unwantedMutations.indexOf(mutation.type) === -1,
});

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    PanelModule: state.PanelModule,
    RetakeModule: state.RetakeModule,
    ReturnModule: state.ReturnModule,
    DeliveryVehicleModule: state.DeliveryVehicleModule,
    // getRidOfThisModule: state.getRidOfThisModule (No one likes it.)
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: (mutation) => unwantedMutations.indexOf(mutation.type) === -1,
});

export default new Vuex.Store({
  state: {
    isOpenSignupPopup: false,
    isOpenSigninPopup: false,
    isOpenVerificationAccount: false,
    isOpenNavbar: false,
  },
  getters: {
    getIsOpenPopup: (state) =>
      state.isOpenSigninPopup || state.isOpenSignupPopup,
    getIsOpenSigninPopup: (state) => state.isOpenSigninPopup,
    getIsOpenVerificationAccount: (state) => state.isOpenVerificationAccount,
    getIsOpenNavbar: (state) => state.isOpenNavbar,
  },
  mutations: {
    setOpenVerificationAccount(state, open) {
      state.isOpenVerificationAccount = open;
    },
    toggleNavbar(state, document) {
      let menu = document.querySelector(".mobile-menu");

      if (!menu.classList.contains("active")) {
        menu.classList.add("active");
        document.body.classList.add("no-scroll");
      } else {
        menu.classList.remove("active");
        document.body.classList.remove("no-scroll");
      }
      state.isOpenNavbar = !state.isOpenNavbar;
    },
  },
  actions: {},
  modules: {
    authentication,
    UserModule,
    PanelModule,
    RetakeModule,
    ReturnModule,
    SpinnerModule,
    AlertModule,
    DeliveryVehicleModule,
  },
  plugins: [vuexLocalStorage.plugin, vuexSessionStorage.plugin],
});
